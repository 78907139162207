import "./CheckboxInput.scss";

type CheckboxInputProps = {
  label: string;
  name: string;
  isChecked: boolean;
  onChange: (value: string, isChecked: boolean) => void;
  className?: string;
};

export const CheckboxInput = ({
  label,
  name,
  isChecked,
  onChange,
  className = "",
}: CheckboxInputProps) => {
  return (
    <label className={`CheckboxInput ${className}`}>
      <input
        type="checkbox"
        name={name}
        className="CheckboxInput__Checkbox"
        checked={isChecked}
        onChange={(e) => onChange(name, e.target.checked)}
      />
      {label}
    </label>
  );
};
