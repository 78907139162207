import { PingLucideIcon } from "@repo/ping-react-js";
import cx from "classnames";

import "./PingActionPanel.scss";

type PingActionsPanelProps = {
  onClick: () => void;
  classNames?: string;
  label: string;
  disabled?: boolean;
};

export const PingActionsPanel = ({
  classNames,
  label,
  onClick,
  disabled
}: PingActionsPanelProps) => {
  return (
    <div
      key={label}
      className={cx("PingActionPanel", classNames, {
        "PingActionPanel--disabled": disabled
      })}
    >
      <button disabled={disabled} className="command" onClick={onClick}>
        <PingLucideIcon iconName={"Send"} />
        {label}
      </button>
    </div>
  );
};
