/*global google*/

import { Marker, AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import { useCallback, useEffect, useRef } from "react";

type GoogleMarkerType = {
  building: any;
  children?: React.ReactNode;
  onClick?: (blg: any) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
  onMouseOver?: (e: google.maps.MapMouseEvent) => void;
  onMouseOut?: (e: google.maps.MapMouseEvent) => void;
  onRightClick?: (e: google.maps.MapMouseEvent) => void;
  icon?: string | google.maps.Icon | null | google.maps.Symbol;
  style?: React.CSSProperties;
  zIndex?: number;
  pinOptions?: google.maps.marker.PinElementOptions;
  draggable?: boolean;
};

const ICONS = {
  SOURCE_INFO:
    "M9,6a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6h0a6,6,0,0,1-6,6h0A6,6,0,0,1,9,6Zm6,6V21",
  SUGGESTED:
    "M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703 C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z",
  ACCEPTED:
    "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
};

export const TEARDROP_MARKER_OPTIONS = {
  DEFAULT: {
    background: "#145df0",
    borderColor: "#4d4d4d",
    glyphColor: "#98b8f1",
  },
  YELLOW: {
    background: "#FFD700",
    borderColor: "#FFA500",
    glyphColor: "#FFFF00",
  },
  SELECTED: {
    background: "#e94234",
    borderColor: "#b31412",
    glyphColor: "#b31412",
  },
  MAGENTA: {
    background: "#FF00FF",
    borderColor: "#8B008B",
    glyphColor: "#FFFFFF",
  },
  ORANGE: {
    background: "#FF6600",
    borderColor: "#CC5500",
    glyphColor: "#FFFFFF",
  },
};

const DEFAULT_PIN_Z_INDEX = 99;
export const PING_BASE_COLOR = "#00FF00";

export const PingGoogleMarker = ({
  onClick,
  onRightClick,
  building,
  style,
  ...restProps
}: GoogleMarkerType) => {
  const handleOnClick = () => {
    onClick?.(building);
  };

  if (!building.lat || !building.lng) {
    return null;
  }

  return (
    <Marker
      position={{ lat: building.lat, lng: building.lng }}
      onRightClick={onRightClick}
      {...restProps}
      onClick={handleOnClick}
    />
  );
};

export const PingGoogleCustomMarker = ({
  onClick,
  onRightClick,
  building,
  ...restProps
}: GoogleMarkerType) => {
  const handleOnClick = () => {
    onClick?.(building);
  };

  if (!building.lat || !building.lng) {
    return null;
  }

  return (
    <Marker
      position={{ lat: building.lat, lng: building.lng }}
      onRightClick={onRightClick}
      {...restProps}
      onClick={handleOnClick}
    />
  );
};

export const PingGoogleCustomAdvancedMarker = ({
  onClick,
  children,
  building,
  onRightClick,
  ...restProps
}: GoogleMarkerType) => {
  const handleOnClick = (e: google.maps.MapMouseEvent) => {
    onClick?.(building);
  };

  if (!building.lat || !building.lng) {
    return null;
  }

  return (
    <AdvancedMarker
      position={{ lat: building.lat, lng: building.lng }}
      onClick={handleOnClick}
      onRightClick={handleOnClick}
      {...restProps}
    >
      {children}
    </AdvancedMarker>
  );
};

export const PingGoogleTeardropMarker = ({
  onClick,
  onRightClick,
  building,
  pinOptions = TEARDROP_MARKER_OPTIONS.DEFAULT,
  zIndex = DEFAULT_PIN_Z_INDEX,
  ...restProps
}: GoogleMarkerType) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null,
  );
  const listenerRemoveRef = useRef<(() => void) | null>(null);
  const handleOnClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      onClick?.(building);
    },
    [onClick, building],
  );

  useEffect(() => {
    const attachRightClickListener = () => {
      const marker = markerRef.current;
      if (marker?.element) {
        const element = marker.element;
        const listener = (e: MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();
          onRightClick?.({
            latLng: {
              lat: () => building.lat,
              lng: () => building.lng,
              equals: () => false,
              toJSON: () => ({ lat: building.lat, lng: building.lng }),
              toUrlValue: () => `${building.lat},${building.lng}`,
            },
            domEvent: e,
            stop: () => {},
          });
        };

        element.addEventListener("contextmenu", listener);
        listenerRemoveRef.current = () =>
          element.removeEventListener("contextmenu", listener);
      }
    };

    const checkAndAttachListener = () => {
      if (markerRef.current?.element) {
        attachRightClickListener();
      } else {
        // Need this so listener actually gets attached after a rerender. Refs are not updated immediately.
        setTimeout(checkAndAttachListener, 100);
      }
    };

    if (onRightClick) {
      checkAndAttachListener();
    }

    return () => {
      if (listenerRemoveRef.current) {
        listenerRemoveRef.current();
        listenerRemoveRef.current = null;
      }
    };
  }, [building, onRightClick]);

  if (!building.lat || !building.lng) {
    return null;
  }

  return (
    <AdvancedMarker
      ref={markerRef}
      position={{ lat: building.lat, lng: building.lng }}
      onClick={handleOnClick}
      clickable={true}
      zIndex={zIndex}
      {...restProps}
    >
      <Pin {...pinOptions} />
    </AdvancedMarker>
  );
};

export const PingGoogleUserLocationMarker = ({
  position,
  hoveredMarker,
  onRightClick,
  onDragEnd,
  ...restProps
}: {
  position: google.maps.LatLngLiteral | null;
  hoveredMarker: string;
  onRightClick: () => void;
  onDragEnd: (e: google.maps.MapMouseEvent) => void;
}) => {
  if (!position || !position.lat || !position.lng) {
    return null;
  }

  const icon = {
    path: ICONS["ACCEPTED"],
    fillColor: hoveredMarker === "USER_ANNOTATION" ? "white" : PING_BASE_COLOR,
    scale: 2,
    fillOpacity: 1,
    strokeColor: "black",
    strokeWeight: 1,
    anchor: new google.maps.Point(0, 20),
    labelOrigin: new window.google.maps.Point(1, 25),
  };

  return (
    <Marker
      key={"USER_ANNOTATION"}
      draggable={true}
      position={position}
      onRightClick={onRightClick}
      onDragEnd={(e) => {
        onDragEnd(e);
      }}
      icon={icon}
      label={{ text: "User Verified", color: "black" }}
      {...restProps}
    />
  );
};

// export const PingGeocodingMarker = ({
//   blg,
//   onClick,
//   onRightClick,
//   onMouseOver,
//   onMouseOut,
//   size = 2.5,
// }: PingMarkerProps) => {
//   const regeocoded = useAppSelector((state) => state.geo.regeocoded);
//   const hoveredMarker = useAppSelector((state) => state.geo.hoveredMarker);
//   const pinColor =
//     regeocoded && !blg?.regeocoded
//       ? blg.s === "OrigSOV"
//         ? GET_SERVICE_COLOR(blg.s)
//         : PREVIOUS_LOCATION_PIN_COLOR
//       : GET_SERVICE_COLOR(blg.s);

//   const selected =
//     blg?.s === hoveredMarker || blg?.merged_services?.includes(hoveredMarker);
//   const color = selected ? "white" : pinColor;
//   const icon = {
//     path: ICONS["SUGGESTED"],
//     fillColor: color,
//     scale: size,
//     anchor: new window.google.maps.Point(7.5, 15),
//     labelOrigin: new window.google.maps.Point(7.5, 6),
//     ...iconProps,
//   };

//   return (
//     <Marker
//       key={JSON.stringify({ selected })}
//       zIndex={9999999999}
//       onMouseOver={() => {
//         onMouseOver?.(blg);
//       }}
//       onMouseOut={() => {
//         onMouseOut?.();
//       }}
//       onClick={onClick}
//       onRightClick={onRightClick}
//       position={{ lat: blg.lat, lng: blg.lng }}
//       icon={icon}
//     />
//   );
// };
