import { useState, useEffect, useRef, useMemo } from "react";
import delay from "lodash/delay";
import { APIProvider, Map, useMap } from "@vis.gl/react-google-maps";
import { SovDataType } from "ts-types/DataTypes";
import { useGetPolicyDataQuery, useGetMapDataQuery } from "services/mapSlice";
import { cellToBoundary } from "h3-js";
import { useAuth } from "utils/hooks";
import { PingBoundsType } from "ts-types/MapTypes";
import { POLICY_DATA_STATUS } from "constants/SubmissionConstants";

import {
  PingGoogleCustomMarker,
  GET_CIRCLE_PIN_ICON,
  PingGooglePolygon
} from "@repo/ping-react-js";

type PingVisionMapLiteProps = {
  selectedItem: SovDataType;
};

type MapWrapperProps = {
  selectedItem: SovDataType;
  plotColor?: string;
};

const NotMapMessage = ({ message }: { message: string }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1rem"
    }}
  >
    {message}
  </div>
);

const MapWrapper = ({ selectedItem, plotColor = "blue" }: MapWrapperProps) => {
  const { accessToken } = useAuth();
  const map = useMap();
  const mapRef = useRef<google.maps.Map | null>(null);
  const [bounds, setBounds] = useState<PingBoundsType | null>(null);
  const mapIsReady = selectedItem?.ping_maps?.status === "R";

  useEffect(() => {
    mapRef.current = map;
  }, [map]);

  useEffect(() => {
    if (bounds && map) {
      delay(() => {
        map?.fitBounds(
          new google.maps.LatLngBounds(
            new google.maps.LatLng(bounds.lat1, bounds.lng1),
            new google.maps.LatLng(bounds.lat2, bounds.lng2)
          )
        );
      }, 1);
    }
  }, [bounds, map]);

  const getSovId = () => {
    if (selectedItem?.ping_maps?.status === "R") {
      // TDOO: there has to be a better fucking way to do this ...
      return selectedItem?.ping_maps?.url?.split("/").pop();
    }
    return null;
  };

  const sovId = getSovId();

  const {
    data: policyData,
    error: policyError,
    isLoading: policyLoading
  } = useGetPolicyDataQuery(
    { sovId: sovId || "", accessToken },
    { skip: !sovId || !accessToken }
  );

  const {
    data: mapData,
    error: mapError,
    isLoading: mapLoading
  } = useGetMapDataQuery(
    {
      accessToken,
      sovId: sovId || "",
      bounds: policyData?.totals?.bbox
    },
    { skip: !sovId || !policyData?.totals?.bbox || !accessToken }
  );

  useEffect(() => {
    if (policyData?.totals?.bbox) {
      setBounds(policyData.totals.bbox);
    }
  }, [policyData]);

  const markers = useMemo(
    () =>
      mapData?.buildings?.map((b) => (
        <PingGoogleCustomMarker
          key={b.item_key}
          building={{ lat: b.data.latitude, lng: b.data.longitude }}
          icon={GET_CIRCLE_PIN_ICON(plotColor, 7)}
        />
      )) || [],
    [mapData, plotColor]
  );

  const polygons = useMemo(
    () =>
      mapData?.hexes?.map((h, i) => (
        <PingGooglePolygon
          key={i}
          paths={cellToBoundary(h.h3_index).map(([lng, lat]) => ({ lat, lng }))}
          strokeColor="white"
          strokeOpacity={1}
          strokeWeight={2}
          fillColor={plotColor}
          fillOpacity={1}
        />
      )) || [],
    [mapData, plotColor]
  );

  if (policyError || mapError)
    return <NotMapMessage message="Failed to load map data." />;
  if (policyLoading || mapLoading) return <NotMapMessage message="Loading" />;
  if (!mapIsReady)
    return (
      <NotMapMessage
        message={`No map is available for this submission. The current status is: ${POLICY_DATA_STATUS?.[selectedItem?.ping_maps?.status_display]}`}
      />
    );

  return (
    <Map
      key={JSON.stringify(bounds)}
      defaultCenter={{ lat: 25.7617, lng: -80.1918 }}
      defaultZoom={12}
      style={{ width: "100%", height: "500px" }}
      mapTypeControl={true}
      streetViewControl={false}
      fullscreenControl={false}
      zoomControl={true}
      scrollwheel={true}
    >
      {markers}
      {polygons}
    </Map>
  );
};

const PingVisionMapLite = ({ selectedItem }: PingVisionMapLiteProps) => (
  <APIProvider apiKey={import.meta.env.VITE_APP_PING_GOOGLE_API_KEY}>
    <MapWrapper selectedItem={selectedItem} />
  </APIProvider>
);

export default PingVisionMapLite;
