import { forwardRef, SelectHTMLAttributes, useCallback } from "react";
import cx from "classnames";

import { PingSelectOptions } from "../../ts-types/InputTypes";

import "./PingSelectInput.scss";

type PingSelectInputProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: PingSelectOptions;
  id?: string;
  className?: string;
  allowEmpty?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

export const PingSelectInput = forwardRef<
  HTMLSelectElement,
  PingSelectInputProps
>(
  (
    {
      options,
      className,
      allowEmpty = false,
      readOnly = false,
      onChange,
      ...restProps
    },
    ref,
  ) => {
    if (allowEmpty) {
      options = [{ label: "Select", value: "" }, ...options];
    }

    const optionElts = options.map((o) => (
      <option key={o.value} value={o.value}>
        {o.label}
      </option>
    ));

    const appliedClasses = cx("PingSelectInput", className);
    const appliedSelectClasses = cx("PingSelectInput__Select", {
      "PingSelectInput__Select--IsReadOnly": readOnly,
      "PingSelectInput__Select--IsDisabled": restProps.disabled,
    });

    const onChangeSafe = useCallback(
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (readOnly) {
          return;
        }

        onChange(e);
      },
      [readOnly, onChange],
    );

    return (
      <div className={appliedClasses}>
        <select
          ref={ref}
          className={appliedSelectClasses}
          onChange={onChangeSafe}
          {...restProps}
        >
          {optionElts}
        </select>
      </div>
    );
  },
);
