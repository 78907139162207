import cx from "classnames";

import {
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger
} from "../panels/PingTooltip";

import "./GridButton.scss";

type GridButtonProps = {
  label: string | React.ReactNode;
  title?: string;
  disabledTitle?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const GridButton = ({
  label,
  title,
  className,
  disabled,
  disabledTitle = "",
  ...restProps
}: GridButtonProps) => {
  const classes = cx("GridButton", className, {
    "GridButton--Disabled": disabled
  });
  return (
    <PingTooltip tooltipRole="label" placement="bottom">
      <PingTooltipTrigger className={classes} {...restProps}>
        {label}
      </PingTooltipTrigger>
      <PingTooltipContent>
        {!disabled ? title : disabledTitle}
      </PingTooltipContent>
    </PingTooltip>
  );
};
