import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SovDataTypeDocument } from "ts-types/DataTypes";

type InitialState = {
  selectedDocuments: SovDataTypeDocument[];
};

const documentSlice = createSlice({
  name: "documents",
  initialState: {
    selectedDocuments: []
  } as InitialState,
  reducers: {
    setSelectDocuments(state, actions: PayloadAction<SovDataTypeDocument[]>) {
      state.selectedDocuments = actions.payload;
    }
  }
});

const { actions, reducer } = documentSlice;

export const { setSelectDocuments } = actions;

export default reducer;
