import React from "react";
import { PingButton } from "@repo/ping-react-js";
import { useSelector } from "react-redux";
import { selectHasNewVersion } from "../services/versionApi";
import "./VersionChecker.scss";

export const VersionChecker: React.FC = () => {
  const hasNewVersion = useSelector(selectHasNewVersion);

  const handleRefresh = () => {
    window.location.reload();
  };

  if (!hasNewVersion) {
    return null;
  }

  return (
    <div className="VersionChecker">
      <span>A new app version is available!</span>
      <PingButton onClick={handleRefresh} label="Refresh" />
    </div>
  );
};
