export const SUBMISSION_STATUSES = {
  N: "New",
  P: "Processing Documents",
  R: "Ready",
  B: "Being Created"
};

export const SUBMISSION_SOURCE_TYPES = {
  E: "Email",
  A: "API",
  W: "Web Upload"
};

export const PAPERTRAIL: Record<string, number> = {
  dev: 39290431,
  prd: null,
  prdeu: null,
  stg: null,
  local: null
};

export const S3_RESULTS_BUCKET: Record<string, string> = {
  dev: "pingvision-dev-incoming",
  prd: "pingvision-prd-incoming",
  prdeu: "pingvision-prdeu-incoming",
  stg: "pingvision-stg-incoming",
  local: "pingvision-local-incoming"
};

export const SUBMISSION_TRIAGE_RESULTS_STATUS = {
  A: "Approved",
  C: "Consider",
  D: "Declined"
};

export const FRONT_END_BASE_URL = "/submission";

export const APP_MODES = {
  DETAIL: "detail"
};

export const NOT_AVAILABLE = "---";

export const POLICY_DATA_STATUS = {
  NOT_INITIALIZED: "Not Initialized",
  INITIALIZING: "Initializing",
  READY: "Ready",
  FAILED: "Failed"
};
