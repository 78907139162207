import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ActivityItemType } from "ts-types/ApiTypes";
import { SovDataType } from "ts-types/DataTypes";

type EmailPreview = {
  type: "EMAIL";
  sovid: string;
};

type PdfPreview = {
  type: "PDF";
  url: string;
};

type EmptyPreview = {
  type: "EMPTY";
};

type DocumentPreview = EmailPreview | PdfPreview | EmptyPreview | null;

type InboxInitialState = {
  /* Current history of selected sovid. */
  submissionHistory: ActivityItemType[];

  /* List of all sovids. */
  sovs: SovDataType[];

  /**
   * Current cursor for our infinitely scrolling submission list.
   */
  currentCursorId: string | null;

  /** The document preview currently being displayed in the preview panel. */
  documentPreview: DocumentPreview;

  /** cached version of slug ID so you list can be maintained as items are clicked but slug goes away. if
   * you are ain a view and need click down the list, the url changes to /submission/<PINGID> but the
   * list needs to be maintained as you click down the list.
   */
  slug: string | null;

  /** cached version of team ID so you list can be maintained as items are clicked but teamid goes away.if
   * you are ain a view and need click down the list, the url changes to /submission/<PINGID> but the
   * list needs to be maintained as you click down the list.
   *
   */
  teamId: string | null;
};

const inboxSlice = createSlice({
  name: "inbox",
  initialState: {
    submissionHistory: [],
    sovs: [],
    currentCursorId: null,
    documentPreview: null,
    slug: null,
    teamId: null,
  } as InboxInitialState,
  reducers: {
    setSubmissionHistory(state, actions: PayloadAction<ActivityItemType[]>) {
      state.submissionHistory = actions.payload;
    },
    setPreviewPdf(state, action: PayloadAction<string>) {
      state.documentPreview = {
        type: "PDF",
        url: action.payload,
      };
    },
    setSovs(state, action: PayloadAction<SovDataType[]>) {
      state.sovs = action.payload;
    },
    setCurrentCursorId(state, action: PayloadAction<string | null>) {
      state.currentCursorId = action.payload;
    },
    setPreviewEmail(state, action: PayloadAction<string>) {
      state.documentPreview = {
        type: "EMAIL",
        sovid: action.payload,
      };
    },
    setPreviewEmpty(state) {
      state.documentPreview = { type: "EMPTY" };
    },
    setClearPreview(state) {
      state.documentPreview = null;
    },
    setSlug(state, action: PayloadAction<string>) {
      state.slug = action.payload;
    },
    setTeamId(state, action: PayloadAction<string>) {
      state.teamId = action.payload;
    },
  },
});

const { actions, reducer } = inboxSlice;

export const {
  setTeamId,
  setSlug,
  setSubmissionHistory,
  setPreviewPdf,
  setPreviewEmail,
  setPreviewEmpty,
  setClearPreview,
  setSovs,
  setCurrentCursorId,
} = actions;

export default reducer;
