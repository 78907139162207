import { getMapsBaseUrl } from "../utils";
import type {
  PolicyDataType,
  PingHexType,
  PingBuildingType,
  PingBoundsType
} from "ts-types/MapTypes";

import { api } from "services/pvSlice";

export const mapApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPolicyData: build.query<
      PolicyDataType,
      { sovId: string; accessToken: string }
    >({
      query: ({ sovId, accessToken }) => {
        const searchParams = new URLSearchParams();
        searchParams.append("sovid", sovId);

        return {
          url: getMapsBaseUrl() + `api/v1/pli/policy_breakdown?${searchParams}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
      }
    }),

    getMapData: build.query<
      { hexes: PingHexType[]; buildings: PingBuildingType[] },
      {
        sovId: string;
        accessToken: string;
        bounds?: PingBoundsType;
      }
    >({
      query: ({ sovId, bounds, accessToken }) => {
        const searchParams = new URLSearchParams();
        searchParams.append("sovid", sovId);
        searchParams.append("limit", "500");

        if (bounds?.lat1 && bounds?.lng1 && bounds?.lat2 && bounds?.lng2) {
          searchParams.append("lat1", bounds.lat1?.toString());
          searchParams.append("lng1", bounds.lng1?.toString());
          searchParams.append("lat2", bounds.lat2?.toString());
          searchParams.append("lng2", bounds.lng2?.toString());
        }

        return {
          url: getMapsBaseUrl() + `api/v1/pli/policy?${searchParams}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
      }
    })
  })
});

export const { useGetPolicyDataQuery, useGetMapDataQuery } = mapApi;
