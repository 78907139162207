import cx from "classnames";

import "./PingCircleIconButton.scss";

type PingCircleIconButtonProps = {
  iconName: string;
  className?: string;
  ariaLabel?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PingCircleIconButton = ({
  iconName,
  className,
  ariaLabel,
  ...restProps
}: PingCircleIconButtonProps) => {
  const classes = cx("PingCircleIconButton", className);
  return (
    <button className={classes} {...(ariaLabel && { 'aria-label': ariaLabel })} {...restProps}>
      <span className="material-icons" aria-hidden={true}>
        {iconName}
      </span>
    </button>
  );
};
