import { useRef, useState, useCallback, useEffect } from "react";
import {
  useFloating,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  Placement,
} from "@floating-ui/react";
import cx from "classnames";
import debounce from "lodash/debounce";

import { PingLucideIcon } from "../icons/PingLucideIcon";
import { PingTextInput } from "./PingTextInput";
import { PingVirtualWindow } from "../virtualization/PingVirtualWindow";

import "./PingVirtualizedSearchSelectInput.scss";

export type Option = {
  label: string;
  value: string;
  searchText?: string;
};

export type PingVirtualizedSearchSelectInputProps = {
  selectedValue: string | null;
  onSelect: (value: string | null, option?: Option | null) => void;
  fetchOptions: ({
    cursor,
    search,
  }: {
    cursor?: string | null;
    search?: string;
  }) => Promise<{
    data: Option[];
    nextCursor?: string | null;
    hasMore: boolean;
    totalCount?: number;
  }>;
  placeholder?: string;
  placement?: Placement;
  value?: string;
  onChange?: (value: string) => void;
  initialOptions?: Option[];
  clearCache?: boolean;
};

export const PingVirtualizedSearchSelectInput = ({
  selectedValue,
  onSelect,
  fetchOptions,
  placeholder,
  placement = "bottom-start",
  value,
  onChange,
  initialOptions = [],
  clearCache,
}: PingVirtualizedSearchSelectInputProps) => {
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [blurInputOnClick, setBlurInputOnClick] = useState(false);
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const listRef = useRef<Array<HTMLElement | null>>([]);

  // Cache for search results that persists while component is mounted
  const searchCache = useRef<{
    [key: string]: {
      data: Option[];
      hasMore: boolean;
      totalCount?: number;
      nextCursor?: string | null;
    };
  }>({});

  // Clear cache every 2 minutes
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        searchCache.current = {};
      },
      2 * 60 * 1000,
    );

    return () => clearInterval(intervalId);
  }, []);

  const getCacheKey = useCallback(
    (searchTerm: string, cursor?: string | null) => {
      return `${searchTerm}:${cursor || ""}`;
    },
    [],
  );

  const loadOptions = useCallback(
    async ({
      cursor,
      search: searchTerm = "",
    }: {
      cursor?: string | null;
      search?: string;
    }) => {
      const cacheKey = getCacheKey(searchTerm, cursor);

      // Check if we have this exact query cached
      if (searchCache.current[cacheKey]) {
        return searchCache.current[cacheKey];
      }

      setIsLoading(true);
      try {
        const result = await fetchOptions({ cursor, search: searchTerm });

        // Cache the results with the cursor
        searchCache.current[cacheKey] = {
          data: result.data,
          hasMore: result.hasMore,
          totalCount: result.totalCount,
          nextCursor: result.nextCursor,
        };

        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchOptions],
  );

  const [lastSearchTerm, setLastSearchTerm] = useState<string>("");

  // Process search text by extracting content from parentheses or handling incomplete cases
  const processSearchText = useCallback((text: string): string => {
    if (!text) return "";

    // First trim any whitespace
    let normalized = text.trim();

    // Find the last opening and closing parentheses
    const openParenIndex = normalized.lastIndexOf("(");
    const closeParenIndex = normalized.lastIndexOf(")");

    if (openParenIndex !== -1) {
      if (closeParenIndex > openParenIndex) {
        // If we have complete parentheses, extract what's inside
        return normalized.substring(openParenIndex + 1, closeParenIndex).trim();
      } else {
        // If parentheses are incomplete, take everything before the opening parenthesis
        return normalized.substring(0, openParenIndex).trim();
      }
    }

    return normalized;
  }, []);

  // Create a stable debounced search function using useRef
  const debouncedSearchRef = useRef(
    debounce(
      async (
        processedSearch: string,
        callback: (search: string) => Promise<void>,
      ) => {
        callback(processedSearch);
      },
      300,
    ),
  );

  const performSearch = useCallback(
    async (processedSearch: string) => {
      setIsLoading(true);
      setLastSearchTerm(processedSearch);
      setOptions([]);
      setNextCursor(null);
      setActiveIndex(0);

      try {
        const result = await loadOptions({
          search: processedSearch,
          cursor: null,
        });
        setOptions(result.data);
        setNextCursor(result.nextCursor);
        setHasMore(result.hasMore);

        if (optionsRef.current) {
          optionsRef.current.scrollToIndex(0);
        }
      } catch {
        setOptions([]);
        setNextCursor(null);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    },
    [loadOptions],
  );

  const handleSearch = useCallback(
    async (search: string) => {
      const processedSearch = processSearchText(search);

      // Don't return early for empty searches
      if (processedSearch === lastSearchTerm && search) {
        return;
      }

      // Cancel any pending debounced searches
      debouncedSearchRef.current.cancel();

      // For empty searches or very short searches, execute immediately without debounce
      if (search === "" || search.length <= 2) {
        await performSearch(processedSearch);
      } else {
        debouncedSearchRef.current(processedSearch, performSearch);
      }
    },
    [lastSearchTerm, processSearchText, performSearch],
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSearchRef.current.cancel();
    };
  }, []);

  const handleClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpen(true); // Ensure dropdown is open
      if (onChange) {
        onChange("");
      }
      onSelect(null, null);
      // Cancel any pending debounced searches
      debouncedSearchRef.current.cancel();
      setActiveIndex(0);
      handleSearch("");
      inputRef.current?.focus();
    },
    [onSelect, onChange, handleSearch, debouncedSearchRef],
  );

  const loadMore = useCallback(async () => {
    if (!hasMore || isLoading || !nextCursor) return;

    setIsLoading(true);
    const result = await loadOptions({ search: value, cursor: nextCursor });
    setOptions((prev) => [...prev, ...result.data]);
    setNextCursor(result.nextCursor);
    setHasMore(result.hasMore);
    setIsLoading(false);
  }, [loadOptions, hasMore, isLoading, nextCursor, value]);

  const handleInputFocus = useCallback(() => {
    if (blurInputOnClick) {
      setBlurInputOnClick(false);
    } else {
      setOpen(true);
      // Only load options if we don't have any and aren't currently loading
      if (
        options.length === 0 &&
        !isLoading &&
        (!inputRef.current?.value || inputRef.current.value.trim() === "")
      ) {
        handleSearch("");
      }
    }
  }, [handleSearch, options.length, isLoading]);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      flip({
        fallbackPlacements: ["bottom-start", "top-start"],
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: "300px",
          });
        },
        padding: 8,
      }),
    ],
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: false,
    orientation: "vertical",
    cols: 1,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
    listNav,
  ]);

  const handleSelect = useCallback(
    (option: Option) => {
      onSelect(option.value, option);
      if (onChange) {
        onChange(option.label);
      }
      // Use context.onOpenChange to handle all state updates
      context.onOpenChange(false);
    },
    [onSelect, onChange, context],
  );

  const handleOptionClick = useCallback(
    (option: Option) => {
      handleSelect(option);
      setActiveIndex(-1);
      context.onOpenChange(false);
      // Ensure input loses focus
      setBlurInputOnClick(true);
    },
    [handleSelect, context],
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (onChange) {
        onChange(newValue);
      }
      if (!open) {
        setOpen(true);
      }

      // Process the search text before searching
      const processedValue = processSearchText(newValue);

      if (processedValue !== lastSearchTerm) {
        handleSearch(processedValue);
      }
    },
    [open, onChange, handleSearch, lastSearchTerm],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        e.stopPropagation();

        if (!open) {
          setOpen(true);
          context.onOpenChange(true);
          return;
        }

        if (options.length === 0) {
          return;
        }

        let currentIndex =
          activeIndex === -1
            ? options.findIndex((opt) => opt.value === selectedValue)
            : activeIndex;

        // If no item is selected yet, start from -1 for ArrowDown or last item for ArrowUp
        if (currentIndex === -1) {
          currentIndex = e.key === "ArrowDown" ? -1 : options.length - 1;
        }

        let nextIndex;
        if (e.key === "ArrowDown") {
          // Stop at the last item
          nextIndex = Math.min(currentIndex + 1, options.length - 1);
        } else {
          // Stop at the first item
          nextIndex = Math.max(currentIndex - 1, 0);
        }

        // Only update if the index actually changed
        if (nextIndex !== currentIndex) {
          setActiveIndex(nextIndex);
          // Ensure the virtual window scrolls to show the active item
          optionsRef.current?.scrollToIndex(nextIndex);
        }
      } else if (e.key === "Enter" && open) {
        e.preventDefault();
        e.stopPropagation();
        if (activeIndex !== -1 && activeIndex < options.length) {
          handleSelect(options[activeIndex]);
        }
      } else if (e.key === "Escape") {
        if (open) {
          setOpen(false);
          context.onOpenChange(false);
          setActiveIndex(-1);
        }
      }
    },
    [open, options, handleSelect, activeIndex, context, selectedValue],
  );

  useEffect(() => {
    if (selectedValue && !value?.trim()) {
      const option = options.find((opt) => opt.value === selectedValue);
      if (option) {
        if (onChange) {
          onChange(option.label);
        }
      }
    }
  }, [selectedValue, options, onChange, value]);

  useEffect(() => {
    if (!value && selectedValue) {
      onSelect(null, null);
    }
  }, [value, selectedValue, onSelect]);

  useEffect(() => {
    if (!options.length && !isLoading && !value) {
      handleSearch("");
    }
  }, [options.length, isLoading, value, handleSearch]);

  useEffect(() => {
    if (!open) return;

    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 100
      ) {
        loadMore();
      }
    };

    container.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [open, loadMore]);

  useEffect(() => {
    setActiveIndex(0);
  }, [lastSearchTerm]); // Only reset when search input changes

  useEffect(() => {
    if (clearCache) {
      searchCache.current = {};
      setLastSearchTerm("");
      setOptions([]);
      setNextCursor(null);
      setHasMore(true);
    }
  }, [clearCache]);

  return (
    <div>
      <div
        ref={refs.setReference}
        className="PingVirtualizedSearchSelectInput__Trigger"
        {...getReferenceProps()}
      >
        <PingTextInput
          ref={inputRef}
          value={value ?? ""}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          aria-expanded={open}
          aria-haspopup="listbox"
          aria-controls={open ? "search-select-dropdown" : undefined}
          role="combobox"
          aria-autocomplete="list"
          aria-activedescendant={
            options.findIndex((opt) => opt.value === selectedValue) !== -1
              ? `option-${selectedValue}`
              : undefined
          }
        />
        <div className="PingVirtualizedSearchSelectInput__Trigger__IconsWrapper">
          {value && (
            <button
              type="button"
              onClick={handleClear}
              className="PingVirtualizedSearchSelectInput__Trigger__ClearButton"
              aria-label="Clear selection"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  handleClear(e as any);
                }
              }}
            >
              <PingLucideIcon iconName="X" size={18} />
            </button>
          )}
          <button
            type="button"
            onClick={() => setOpen(!open)}
            onMouseDown={(e) => e.preventDefault()}
            className="PingVirtualizedSearchSelectInput__Trigger__ChevronButton"
            aria-label={open ? "Close dropdown" : "Open dropdown"}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                setOpen(!open);
              }
            }}
          >
            <PingLucideIcon iconName="ChevronDown" size={18} />
          </button>
        </div>
      </div>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            modal={false}
            initialFocus={-1}
          >
            <div
              ref={refs.setFloating}
              className="PingVirtualizedSearchSelectInput__dropdown"
              style={{
                ...floatingStyles,
                position: "fixed",
                width: (refs.reference.current as HTMLElement)?.offsetWidth,
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                border: "1px solid #e8e8e8",
                borderRadius: "4px",
                backgroundColor: "white",
                zIndex: 1000,
                padding: 0,
                overflow: "hidden",
              }}
              {...getFloatingProps()}
              role="listbox"
              aria-label="Search options"
            >
              {!isLoading && options.length === 0 && (
                <div className="PingVirtualizedSearchSelectInput__no-results">
                  No results found
                </div>
              )}
              {options.length > 0 && (
                <PingVirtualWindow
                  ref={optionsRef}
                  windowHeight={Math.min(300, options.length * 40)}
                  items={options}
                  resetScroll={!!value}
                  renderItem={(option: Option, index: number) => (
                    <div
                      ref={(node) => {
                        if (listRef.current && node) {
                          listRef.current[index] = node;
                        }
                      }}
                      key={option.value}
                      role="option"
                      aria-selected={activeIndex === index}
                      className={cx(
                        "PingVirtualizedSearchSelectInput__option",
                        {
                          "PingVirtualizedSearchSelectInput__option--active":
                            activeIndex === index,
                          "PingVirtualizedSearchSelectInput__option--selected":
                            option.value === selectedValue,
                        },
                      )}
                      onClick={() => handleOptionClick(option)}
                      onMouseEnter={() => setActiveIndex(index)}
                    >
                      {option.label}
                      {option.value === selectedValue && (
                        <PingLucideIcon
                          iconName="Check"
                          size={18}
                          className="tickmark"
                          color="#2684ff"
                        />
                      )}
                    </div>
                  )}
                  onEndReached={loadMore}
                  endReachedThreshold={150}
                />
              )}
              {isLoading && (
                <div className="PingVirtualizedSearchSelectInput__loading">
                  Loading...
                </div>
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
