const SIZE = 20;

type IconProps = {
  width?: number;
  height?: number;
  onClick?: () => void;
};

export const UnknownIcon = ({
  width = SIZE,
  height = SIZE,
  onClick
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={width}
    height={height}
    onClick={onClick}
  >
    <g fill="none" fillRule="evenodd">
      <polygon fill="#9AA7B0" fillOpacity="0.8" points="7 1 3 5 7 5" />
      <path
        fill="#9AA7B0"
        fillOpacity="0.8"
        d="M10.9997,15 L2.9997,15 L2.9997,6 L7.9997,6 L7.9997,1 L12.9997,1 C12.9914069,2.84997911 12.9838819,4.51666712 12.9771251,6.00006403 C10.7785117,6.01237564 9,7.79849099 9,10 C9,10.231552 9.04249042,10.5667624 9.12747127,11.0056312 C9.7627201,11.0056312 10.3867963,11.0056312 10.9997,11.0056312 L10.9997,15 Z"
      />
      <path
        fill="#40B6E0"
        d="M12.25,16 L13.75,16 L13.75,14.5 L12.25,14.5 L12.25,16 Z M13,7 C11.3425,7 10,8.3425 10,10 L11.5,10 C11.5,9.175 12.175,8.5 13,8.5 C13.825,8.5 14.5,9.175 14.5,10 C14.5,11.5 12.25,11.3125 12.25,13.75 L13.75,13.75 C13.75,12.0625 16,11.875 16,10 C16,8.3425 14.6575,7 13,7 Z"
      />
    </g>
  </svg>
);

export const ExcelIcon = ({
  width = SIZE,
  height = SIZE,
  onClick
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      fill="#4CAF50"
      d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
    />
    <path
      fill="#FFF"
      d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
    />
    <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z" />
    <path
      fill="#FFF"
      d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
    />
  </svg>
);

export const PdfIcon = ({
  width = SIZE,
  height = SIZE,
  onClick
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    onClick={onClick}
  >
    <g transform="scale(0.079) translate(-20 -20)">
      <polygon
        fill="#E8E8E8"
        points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525"
      />
      <path
        fill="#FB3449"
        d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387"
      />
      <polygon
        fill="#FB3449"
        points="227.64,25.263 32.842,25.263 32.842,0 219.821,0"
      />
      <g>
        <path
          fill="#A4A9AD"
          d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
              v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z"
        />
        <path
          fill="#A4A9AD"
          d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
              h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z"
        />
        <path
          fill="#A4A9AD"
          d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
              L196.579,273.871L196.579,273.871z"
        />
      </g>
      <polygon
        fill="#D1D3D3"
        points="219.821,50.525 270.346,50.525 219.821,0"
      />
    </g>
  </svg>
);
