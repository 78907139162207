import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useAuth, usePingId } from "utils/hooks";

import { useUploadDocumentMutation } from "../services/pvSlice";

import "./PingUploadWrapper.scss";

type PingUploadWrapperProps = {
  children: React.ReactNode;
};

const PingUploadWrapper: React.FC<PingUploadWrapperProps> = ({ children }) => {
  const { accessToken } = useAuth();
  const pingId = usePingId();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadDocument] = useUploadDocumentMutation();

  const handleUpload = useCallback(
    async (file: File) => {
      if (!file || !pingId) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);

      setIsUploading(true);
      try {
        const response = await uploadDocument({
          accessToken,
          id: pingId,
          file: formData,
        });
        if (response?.data?.message) {
          toast.success("File uploaded successfully");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    },
    [pingId, accessToken, uploadDocument]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        handleUpload(acceptedFiles[0]);
      }
    },
    [handleUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const inputProps = {
    ...getInputProps(),
  };

  return (
    <div
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
      className={`PingUploadWrapper ${isDragActive ? "PingUploadWrapper--active" : ""} ${isUploading ? "PingUploadWrapper--uploading" : ""}`}
    >
      <input {...inputProps} />
      {isUploading ? (
        <div className="PingUploadWrapper__uploading">Uploading...</div>
      ) : (
        children
      )}
    </div>
  );
};

export default PingUploadWrapper;
