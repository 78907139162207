import { useState, useEffect } from "react";
import { SovDataType } from "ts-types/DataTypes";
import { useCallAoAQuery, useCallOpenAiQuery } from "services/aoaSlice";

type PingBrightQueryViewProps = {
  selectedItem: SovDataType;
};

import "./PVThirdPartyDataView.scss"; //

export const PingBrightQueryView = ({
  selectedItem
}: PingBrightQueryViewProps) => {
  const [aoaError, setAoaError] = useState<string | null>(null);
  const [callAPI, setCallApi] = useState<boolean>(false);

  const { data: response, isLoading } = useCallAoAQuery(
    {
      address: `${selectedItem?.insured_street} ${selectedItem?.insured_city} ${selectedItem?.insured_state} ${selectedItem?.insured_zip}`,
      source: "BQ",
      company_website: selectedItem?.insured_website,
      company_name: selectedItem?.insured_name,
      company_ein: selectedItem?.insured_fein
    },
    { skip: !callAPI }
  );

  useEffect(() => {
    setAoaError(null);
    setCallApi(false);
  }, [selectedItem]);

  useEffect(() => {
    if (response?.location_data?.BQ?.error_message) {
      setAoaError(response?.location_data?.BQ?.error_message);
    }
    if (response?.location_data?.BQ) {
      setCallApi(false);
    }
  }, [response]);

  // console.info("response", response?.location_data?.BQ);

  return (
    <div className="PingBrightQueryView">
      <div className="PingBrightQueryView__Header">
        <button
          type="button"
          className="PingButton"
          disabled={isLoading}
          onClick={() => setCallApi(true)}
        >
          {isLoading ? "Loading..." : "Run"}
        </button>
      </div>
      <div className="PingBrightQueryView__Response">
        <div className="PingBrightQueryView__Response__Content">{aoaError}</div>
      </div>
    </div>
  );
};

const getBusinessDescription = (
  openai_response: Record<string, string>
): string => {
  const { insured_website, insured_business_description } = openai_response;

  let description = insured_business_description;
  if (insured_website) {
    description += ` The website is <a href="${insured_website}" target="blank">here</a>.`;
  }

  return description;
};

export const PingOpenAiView = ({ selectedItem }: PingBrightQueryViewProps) => {
  const [aoaError, setAoaError] = useState<string | null>(null);
  const [businessDescription, setBusinessDescription] = useState<string | null>(
    null
  );

  const [callAPI, setCallApi] = useState<boolean>(false);

  const PROMPT =
    "You are an advanced insured tech assistant. Your task is to create a comprehensive and accurate summary of a business based on the details I provide. You should search the internet to gather and validate publicly available information to enhance the accuracy and completeness of the summary. Prioritize factual accuracy, relevance, and clarity in your response. Please try to determine the size of the business and the website in the description.";

  const {
    insured_city,
    insured_state,
    insured_zip,
    insured_country,
    insured_fein,
    insured_name,
    insured_street,
    insured_website,
    insured_business_description
  } = selectedItem;

  const llm_text_blob = {
    openai_response: {
      messages: [
        {
          content: PROMPT,
          role: "system"
        },
        {
          content: JSON.stringify({
            insured_city,
            insured_state,
            insured_zip,
            insured_country,
            insured_fein,
            insured_name,
            insured_street,
            insured_website,
            insured_business_description
          }),
          role: "user"
        }
      ],
      model: "gpt-4o-2024-08-06",
      response_format: {
        type: "json_schema",
        json_schema: {
          strict: true,
          name: "protection_response",
          schema: {
            type: "object",
            properties: {
              insured_city: { type: "string" },
              insured_business_description: { type: "string" },
              insured_country: { type: "string" },
              insured_fein: { type: "string" },
              insured_name: { type: "string" },
              insured_state: { type: "string" },
              insured_street: { type: "string" },
              insured_website: { type: "string" },
              insured_zip: { type: "string" }
            },
            required: [
              "insured_name",
              "insured_street",
              "insured_city",
              "insured_state",
              "insured_zip",
              "insured_country",
              "insured_fein",
              "insured_website",
              "insured_business_description"
            ],
            additionalProperties: false
          }
        }
      }
    }
  };

  const { data: response, isLoading } = useCallOpenAiQuery(
    {
      llm_text_blob: JSON.stringify(llm_text_blob)
    },
    { skip: !callAPI }
  );

  useEffect(() => {
    setAoaError(
      !selectedItem?.insured_name
        ? "Missing insured business name. Cannot run summary."
        : null
    );
    setBusinessDescription(null);
    setCallApi(false);
  }, [selectedItem]);

  useEffect(() => {
    if (response?.location_data?.OAGC?.error_message) {
      setAoaError(response?.location_data?.OAGC?.error_message);
    }
    if (response?.location_data?.OAGC?.is_success) {
      setBusinessDescription(
        getBusinessDescription(
          response?.location_data?.OAGC?.llm_structured_response
            ?.openai_response
        )
      );
    }
    if (response?.location_data?.OAGC) {
      setCallApi(false);
    }
  }, [response]);

  return (
    <div className="PingBrightQueryView">
      <div className="PingBrightQueryView__Header">
        <button
          type="button"
          className="PingButton"
          disabled={isLoading || !insured_name}
          onClick={() => setCallApi(true)}
        >
          {isLoading ? "Generating Summary..." : "Run"}
        </button>
      </div>
      <div className="PingBrightQueryView__Response">
        <div
          className="PingBrightQueryView__Response__Content"
          dangerouslySetInnerHTML={{
            __html: businessDescription || aoaError || ""
          }}
        />
      </div>
    </div>
  );
};
