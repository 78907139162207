import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from "react";
import cx from "classnames";

import { PingMaterialIcon } from "../icons/PingMaterialIcon";

import "./PingTextInput.scss";

type PingTextInputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  uiSize?: "small" | "medium";
  rounded?: boolean;
  className?: string;
  inputClassName?: string;
  id?: string;
};

export const PingTextInput = forwardRef<HTMLInputElement, PingTextInputProps>(
  (
    {
      uiSize = "small",
      rounded = true,
      className,
      inputClassName,
      ...restProps
    },
    ref,
  ) => {
    const appliedClasses = cx("PingTextInput", className, {
      "PingTextInput--hasIcon": restProps.type === "search",
      "PingTextInput--Small": uiSize === "small",
      "PingTextInput--Medium": uiSize === "medium",
    });

    const appliedInputClasses = cx(
      "PingTextInput__Input",
      {
        "PingTextInput__Input--Small": uiSize === "small",
        "PingTextInput__Input--Medium": uiSize === "medium",
        "PingTextInput__Input--Rounded": rounded,
      },
      inputClassName,
    );

    const appliedIconClasses = cx("PingTextInput__Icon", {
      "PingTextInput__Icon--Small": uiSize === "small",
      "PingTextInput__Icon--Medium": uiSize === "medium",
      "PingTextInput__Icon--Rounded": rounded,
    });

    return (
      <div className={appliedClasses}>
        {restProps.type === "search" && (
          <PingMaterialIcon iconName="search" className={appliedIconClasses} />
        )}
        <input ref={ref} className={appliedInputClasses} {...restProps} />
      </div>
    );
  },
);
