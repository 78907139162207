import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Track workflow status updates, for knowing which are from the same user as the logged in user so that
// we can remove those from the submissions status list without prematurely invalidating the cache

interface WorkflowUpdate {
  submissionId: string;
  timestamp: number;
  workflowStatusId: string;
  changedById: string;
}

interface WorkflowUpdatesState {
  recentUpdates: WorkflowUpdate[];
  maxUpdates: number;
}

const initialState: WorkflowUpdatesState = {
  recentUpdates: [],
  maxUpdates: 2000, // Adjust this number based on your needs
};

export const workflowUpdatesSlice = createSlice({
  name: 'workflowUpdates',
  initialState,
  reducers: {
    addWorkflowUpdate: (state, action: PayloadAction<WorkflowUpdate>) => {
      const existingIndex = state.recentUpdates.findIndex(
        update => update.submissionId === action.payload.submissionId
      );

      if (existingIndex !== -1) {
        state.recentUpdates.splice(existingIndex, 1);
      }

      // Add new update at the beginning
      state.recentUpdates.unshift(action.payload);
      
      // If we exceed maxUpdates, remove the oldest items
      if (state.recentUpdates.length > state.maxUpdates) {
        state.recentUpdates = state.recentUpdates.slice(0, state.maxUpdates);
      }
    },
    clearWorkflowUpdates: (state) => {
      state.recentUpdates = [];
    },
  },
});

export const { addWorkflowUpdate, clearWorkflowUpdates } = workflowUpdatesSlice.actions;
export default workflowUpdatesSlice.reducer;
