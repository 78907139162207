import { FC, useCallback, useState } from "react";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatCurrency } from "utils/formatters";

import { PingButton } from "@repo/ping-react-js";

import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "features/submission-dashboard/PVFormsLayouts";
import {
  PVTextInputFormField,
  PVTextareaFormField,
} from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionMutation } from "services/pvSlice";
import { SovDataType } from "ts-types/DataTypes";
import { emptyStringToNull } from "utils/zod-utils";

const formSchema = z.object({
  uw_rules: emptyStringToNull(),
  losses_in_5_years: emptyStringToNull(),
  num_buildings: emptyStringToNull(),
  tiv: emptyStringToNull(),
  readiness: emptyStringToNull(),
  scrubbing_notes: emptyStringToNull(),
  data_readiness_notes: emptyStringToNull(),
  limits_total_limit: emptyStringToNull(),
});

type FormInput = z.infer<typeof formSchema>;

type PVSOVReviewProps = {
  selectedItem: SovDataType;
};

export const PVSOVReview: FC<PVSOVReviewProps> = ({ selectedItem }) => {
  const {
    register,
    formState: { errors },
  } = useForm<FormInput>({ resolver: zodResolver(formSchema) });

  return (
    <form className="PVSOVReviewAndPingOutput">
      <PVScrollSpySectionHeader title="SOV Review"></PVScrollSpySectionHeader>

      <PVInputsColumnsGrid>
        {/* First Column */}
        <PVInputsColumn title="Review Details">
          <PVTextInputFormField
            label="UW Rules"
            name="uw_rules"
            currentValue={selectedItem.uw_rules || "---"}
            register={register}
            error={errors.uw_rules}
            readOnly={true}
            isEditing={false}
          />

          <PVTextInputFormField
            label="Losses in 5 years"
            name="losses_in_5_years"
            currentValue={"---"}
            register={register}
            error={errors.losses_in_5_years}
            readOnly={true}
            isEditing={false}
          />

          <PVTextInputFormField
            label="# of Buildings"
            name="num_buildings"
            currentValue={selectedItem.num_buildings || "---"}
            register={register}
            error={errors.num_buildings}
            readOnly={true}
            isEditing={false}
          />

          <PVTextInputFormField
            label="TIV"
            name="limits_total_limit"
            currentValue={
              formatCurrency(selectedItem.limits_total_limit) || "---"
            }
            register={register}
            error={errors.limits_total_limit}
            readOnly={false}
            isEditing={false}
          />

          <PVTextInputFormField
            label="Readiness"
            name="readiness"
            currentValue={selectedItem.data_readiness_score || "---"}
            register={register}
            error={errors.readiness}
            readOnly={false}
            isEditing={false}
          />
        </PVInputsColumn>

        {/* Second Column */}
        <PVInputsColumn title="Notes">
          <PVTextareaFormField
            label="Scrubbing Notes"
            name="scrubbing_notes"
            currentValue={"---"}
            register={register}
            error={errors.scrubbing_notes}
            readOnly={true}
            isEditing={false}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
