import React, {
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
} from "react";
import "./PingVirtualWindow.scss";

export interface PingVirtualWindowProps<T> {
  items: T[];
  windowHeight: number;
  renderItem: (item: T, index: number) => React.ReactNode;
  onEndReached?: () => Promise<void>;
  endReachedThreshold?: number;
  className?: string;
  resetScroll?: boolean;
  /**
   * Number of items to render beyond the visible window.
   * Higher values make scrolling smoother but impact performance.
   * Recommended values: 1-3 for static content, 3-5 for dynamic content.
   */
  overscanCount?: number;
}

export interface PingVirtualWindowRef {
  scrollToIndex: (index: number) => void;
}

export const PingVirtualWindow = forwardRef(
  <T,>(
    {
      items,
      windowHeight,
      renderItem,
      onEndReached,
      endReachedThreshold = 200,
      className = "",
      resetScroll = false,
      overscanCount = 3,
    }: PingVirtualWindowProps<T>,
    ref: React.ForwardedRef<PingVirtualWindowRef>,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = useState(0);
    const lastScrollTop = useRef(0);
    const scrollingDirection = useRef<"up" | "down">("down");

    // Store heights for each item
    const itemHeights = useRef<{ [key: number]: number }>({});
    const [averageHeight, setAverageHeight] = useState(0); // Default height

    // Function to measure and store item height
    const measureItem = useCallback(
      (index: number, node: HTMLElement | null) => {
        if (node) {
          const height = node.getBoundingClientRect().height;
          if (height > 0 && itemHeights.current[index] !== height) {
            itemHeights.current[index] = height;
            // Update average height
            const heights = Object.values(itemHeights.current);
            const newAverage =
              heights.reduce((sum, h) => sum + h, 0) / heights.length;
            setAverageHeight(newAverage);
          }
        }
      },
      [],
    );

    // Calculate position for an item
    const getItemPosition = useCallback(
      (index: number) => {
        let position = 0;
        for (let i = 0; i < index; i++) {
          position += itemHeights.current[i] || averageHeight;
        }
        return position;
      },
      [averageHeight],
    );

    const { totalHeight, startIndex, visibleItemsArray } =
      useMemo(() => {
        // Calculate total height using known heights or average
        let total = 0;
        for (let i = 0; i < items.length; i++) {
          total += itemHeights.current[i] || averageHeight;
        }

        // Find start index based on scroll position
        let currentHeight = 0;
        let start = 0;
        while (start < items.length && currentHeight < scrollTop) {
          currentHeight += itemHeights.current[start] || averageHeight;
          start++;
        }
        start = Math.max(0, start - overscanCount);

        // Find end index based on window height
        let end = start;
        let heightSum = currentHeight;
        while (
          end < items.length &&
          heightSum < scrollTop + windowHeight + endReachedThreshold
        ) {
          heightSum += itemHeights.current[end] || averageHeight;
          end++;
        }
        end = Math.min(items.length, end + overscanCount);

        return {
          totalHeight: total,
          startIndex: start,
          endIndex: end,
          visibleItemsArray: items.slice(start, end),
        };
      }, [
        items,
        scrollTop,
        windowHeight,
        averageHeight,
        overscanCount,
        endReachedThreshold,
      ]);

    useImperativeHandle(
      ref,
      () => ({
        scrollToIndex: (index: number) => {
          const container = containerRef.current;
          if (container) {
            container.scrollTop = getItemPosition(index);
          }
        },
      }),
      [getItemPosition],
    );

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      if (resetScroll) {
        container.scrollTop = 0;
        setScrollTop(0);
      }
    }, [resetScroll]);

    const handleScroll = useCallback(
      (e: React.UIEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        const newScrollTop = target.scrollTop;

        scrollingDirection.current =
          newScrollTop > lastScrollTop.current ? "down" : "up";
        lastScrollTop.current = newScrollTop;
        setScrollTop(newScrollTop);

        if (!onEndReached || target.dataset.loading) return;

        const { scrollHeight, clientHeight } = target;
        const remainingScroll = scrollHeight - newScrollTop - clientHeight;

        if (remainingScroll <= endReachedThreshold) {
          target.dataset.loading = "true";
          Promise.resolve(onEndReached()).finally(() => {
            if (target) {
              target.dataset.loading = "";
            }
          });
        }
      },
      [endReachedThreshold, onEndReached],
    );

    return (
      <div
        ref={containerRef}
        className={`PingVirtualWindow ${className}`}
        style={{
          height: windowHeight,
          overflowY: "auto",
          position: "relative",
        }}
        onScroll={handleScroll}
      >
        <div
          style={{
            height: totalHeight,
            position: "relative",
          }}
        >
          {visibleItemsArray.map((item, index) => (
            <div
              key={startIndex + index}
              className="virtual-item"
              ref={(node) => measureItem(startIndex + index, node)}
              style={{
                position: "absolute",
                transform: `translate3d(0, ${getItemPosition(startIndex + index)}px, 0)`,
                width: "100%",
                willChange: "transform",
              }}
            >
              {renderItem(item, startIndex + index)}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

export default PingVirtualWindow;
