import { forwardRef, ForwardedRef } from "react";
import { useLogoutFunction } from "@propelauth/react";
import cx from "classnames";

import { PingLucideIcon } from "@repo/ping-react-js";

import "./PVRibbon.scss";

const ICON_SIZE = 30;

type PVRibbonProps = {
  onClickToggle: () => void;
  hasBorder?: boolean;
};

const PVRibbon = forwardRef(
  (
    { onClickToggle, hasBorder = true }: PVRibbonProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const logout = useLogoutFunction();

    const appliedClasses = cx("PVRibbon", {
      "PVRibbon--HasBorder": hasBorder,
    });

    return (
      <div ref={ref} className={appliedClasses}>
        <div className="PVRibbon__Top">
          <button className="PVRibbon__Button">
            <PingLucideIcon
              className="PVRibbon__Button__Icon"
              size={ICON_SIZE}
              iconName="List"
              onClick={onClickToggle}
            />
          </button>
        </div>
        <div className="PVRibbon__Bottom">
          <button className="PVRibbon__Button">
            <PingLucideIcon
              size={ICON_SIZE}
              iconName="LogOut"
              onClick={() => {
                logout(false);
              }}
            />
          </button>
        </div>
      </div>
    );
  }
);

PVRibbon.displayName = "PVRibbon";

export { PVRibbon };
