import * as React from "react";
import ReactDOMServer from "react-dom/server";
import { NON_GEOCODING_PIN_SIZE } from "../Constants";

type PinType = {
  color: string;
  size: number;
  onDoubleClick?: (e: any) => void;
  opacity?: number;
  strokeWidth?: number;
};

const pinStyle = {
  cursor: "pointer",
  fill: "#d00",
  stroke: "none"
};

export const GET_CIRCLE_PIN_ICON = (
  color: string,
  size: number = NON_GEOCODING_PIN_SIZE,
  opacity: number = 1,
  strokeWidth: number = 1
) => {
  const svgString = ReactDOMServer.renderToString(
    <GoogleCirclePin
      color={color}
      size={size}
      opacity={opacity}
      strokeWidth={strokeWidth}
    />
  );
  const encodedSvg = btoa(svgString);

  return `data:image/svg+xml;base64,${encodedSvg}`;
};

const GoogleCirclePin = ({
  size,
  onDoubleClick,
  color = "black",
  opacity = 1,
  strokeWidth = 1
}: PinType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      onDoubleClick={onDoubleClick}
      style={{ ...pinStyle, fill: color }}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - strokeWidth / 2}
        fill={color}
        stroke="black"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
    </svg>
  );
};

export default React.memo(GoogleCirclePin);
