import { FC, useCallback, useState, useEffect } from "react";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { PingButton, US_STATES } from "@repo/ping-react-js";
import { format } from "date-fns";

import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "features/submission-dashboard/PVFormsLayouts";
import {
  PVSelectInputFormField,
  PVTextInputFormField,
  PVLabel,
} from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionMutation } from "services/pvSlice";
import { emptyStringToNull, requiredString } from "utils/zod-utils";
import { SovDataType } from "ts-types/DataTypes";

import "./PVInsuredInformationForm.scss";

const formSchema = z.object({
  insured_name: z.string().trim().optional(),
  insured_street: emptyStringToNull().optional(),
  insured_street2: emptyStringToNull().optional(),
  insured_city: emptyStringToNull().optional(),
  insured_state: emptyStringToNull().optional(),
  insured_zip: emptyStringToNull().optional(),
  insured_country: emptyStringToNull().optional(),
  home_state: emptyStringToNull().optional(),
  insured_fein: emptyStringToNull().optional(),
  insured_website: emptyStringToNull().optional(),
  insured_contact_name: emptyStringToNull().optional(),
  insured_contact_email: emptyStringToNull().optional(),
  insured_contact_phone: emptyStringToNull().optional(),
  insured_business_description: emptyStringToNull().optional(),
  contact_name: emptyStringToNull().optional(),
  contact_email: emptyStringToNull().optional(),
  contact_phone: emptyStringToNull().optional(),
  insured_sic: emptyStringToNull().optional(),
  insured_naics: emptyStringToNull().optional(),
  legal_entity: emptyStringToNull().optional(),
  acord: emptyStringToNull().optional(),
  signed: emptyStringToNull().optional(),
  ofac: emptyStringToNull().optional(),
  sov: emptyStringToNull().optional(),
  loss_runs: emptyStringToNull().optional(),
  supplemental: emptyStringToNull().optional(),
  transunion_button_score: emptyStringToNull().optional(),
  inception_date: emptyStringToNull().optional(),
  expiration_date: emptyStringToNull().optional(),
  client_ref: emptyStringToNull().optional(),
  insured_gl_code: emptyStringToNull().optional(),
  dnb: emptyStringToNull().optional(),
});

type FormInput = z.infer<typeof formSchema>;

type PVInsuredInformationFormProps = {
  selectedItem: SovDataType;
};

const FormBreaker = ({ marginTop }: { marginTop: string }) => (
  <>
    <label style={{ marginTop }} />
    <div />
  </>
);
export const PVInsuredInformationForm: FC<PVInsuredInformationFormProps> = ({
  selectedItem,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInput>({ resolver: zodResolver(formSchema) });

  const [updateSubmission, { isLoading: isSubmitting }] =
    useUpdateSubmissionMutation();

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const result = await updateSubmission({ id: selectedItem.id, data });
      if (!result.error) {
        setIsEditing(false);
        return;
      }

      setServerErrors(result.error.data, setError);
    },
    [updateSubmission, selectedItem.id, setError]
  );

  const onReset = useCallback(() => {
    reset();
    setIsEditing(false);
  }, [reset]);

  useEffect(() => {
    if (selectedItem.id) {
      setIsEditing(false);
    }
  }, [selectedItem]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="PVInsuredInformationForm"
    >
      <PVScrollSpySectionHeader title="Insured Information">
        {isEditing ? (
          <>
            <PingButton
              label="Cancel"
              type="button"
              onClick={onReset}
              disabled={isSubmitting}
            />
            <PingButton
              label={isSubmitting ? "Saving …" : "Save"}
              type="submit"
              variant="primary"
              disabled={isSubmitting}
            />
          </>
        ) : (
          <PingButton
            label="Edit"
            type="button"
            onClick={() => setIsEditing(true)}
          />
        )}
      </PVScrollSpySectionHeader>

      <PVInputsColumnsGrid>
        {/* First Column */}
        <PVInputsColumn title="">
          <PVLabel label="Client Ref" value={selectedItem.client_ref} />
          <PVTextInputFormField
            label="Insured Name"
            name="insured_name"
            currentValue={selectedItem.insured_name}
            register={register}
            error={errors.insured_name}
            readOnly={isSubmitting}
            isEditing={false}
          />
          <PVTextInputFormField
            label="Address 1"
            name="insured_street"
            currentValue={selectedItem.insured_street || "---"}
            register={register}
            error={errors.insured_street}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Address 2"
            name="insured_street2"
            currentValue={selectedItem.insured_street2 || "---"}
            register={register}
            error={errors.insured_street2}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="City"
            name="insured_city"
            currentValue={selectedItem.insured_city || "---"}
            register={register}
            error={errors.insured_city}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVSelectInputFormField
            label="State"
            name="insured_state"
            options={[{ label: "Select state", value: "" }].concat(
              US_STATES.map((s) => ({ label: s.label, value: s.label }))
            )}
            register={register}
            currentValue={selectedItem.insured_state || ""}
            error={errors.insured_state}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="ZIP"
            name="insured_zip"
            currentValue={selectedItem.insured_zip || "---"}
            register={register}
            error={errors.insured_zip}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Country"
            name="insured_country"
            currentValue={selectedItem.insured_country || "---"}
            register={register}
            error={errors.insured_country}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <FormBreaker marginTop="2vh" />

          <PVTextInputFormField
            label="Legal Entity"
            name="legal_entity"
            currentValue={"---"}
            register={register}
            error={errors.insured_country}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Home State"
            name="legal_entity"
            currentValue={"---"}
            register={register}
            error={errors.insured_country}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="OFAC"
            name="legal_entity"
            currentValue={"---"}
            register={register}
            error={errors.insured_country}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="D&B"
            name="legal_entity"
            currentValue={"---"}
            register={register}
            error={errors.insured_country}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
        </PVInputsColumn>

        {/* Second Column */}
        <PVInputsColumn title="">
          <PVTextInputFormField
            label="Contact Name"
            name="contact_name"
            currentValue={selectedItem.insured_contact_name}
            disabled={isSubmitting}
            placeholder="---"
            register={register}
            error={errors.insured_contact_name}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Contact Email"
            name="contact_email"
            currentValue={selectedItem.insured_contact_email}
            placeholder="---"
            disabled={isSubmitting}
            register={register}
            error={errors.insured_contact_email}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
          <PVTextInputFormField
            label="Contact Phone"
            name="contact_phone"
            disabled={isSubmitting}
            currentValue={selectedItem.insured_contact_phone}
            placeholder="---"
            register={register}
            error={errors.insured_contact_phone}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Website"
            name="insured_website"
            currentValue={selectedItem.insured_website}
            placeholder="---"
            register={register}
            disabled={isSubmitting}
            error={errors.insured_website}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="FEIN"
            name="insured_fein"
            currentValue={selectedItem.insured_fein}
            placeholder="---"
            register={register}
            disabled={isSubmitting}
            error={errors.insured_fein}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="GL Code"
            name="insured_gl_code"
            currentValue={selectedItem.insured_gl_code}
            placeholder="---"
            register={register}
            disabled={isSubmitting}
            error={errors.insured_gl_code}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="SIC"
            name="insured_sic"
            currentValue={selectedItem.insured_sic}
            placeholder="---"
            register={register}
            disabled={isSubmitting}
            error={errors.insured_sic}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="NAICS"
            name="insured_naics"
            currentValue={selectedItem.insured_naics}
            placeholder="---"
            register={register}
            disabled={isSubmitting}
            error={errors.insured_naics}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <FormBreaker marginTop="2vh" />

          <PVTextInputFormField
            label="Inception date"
            name="inception_date"
            id="inception_date"
            type="date"
            currentValue={
              selectedItem.inception_date
                ? format(selectedItem.inception_date, "MMMM do, yyyy")
                : selectedItem.inception_date
            }
            register={register}
            error={errors.inception_date}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Expiration date"
            name="expiration_date"
            id="expiration_date"
            type="date"
            currentValue={
              selectedItem.expiration_date
                ? format(selectedItem.expiration_date, "MMMM do, yyyy")
                : selectedItem.expiration_date
            }
            register={register}
            error={errors.expiration_date}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
