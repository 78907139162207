import { FC, useCallback, useState, useRef } from "react";
import {
  Document as ReactPdfDocument,
  Page,
  pdfjs,
  DocumentProps,
} from "react-pdf";

import { useWindowSize } from "@uidotdev/usehooks";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { PingCircleIconButton } from "../inputs/PingCircleIconButton";
import { PingButton } from "../inputs/PingButton";
import { initiateDownload, SiteHeaderActionButton } from "@repo/ping-react-js";
import { useAuth } from "ping-vision-client/src/utils/hooks.ts";

import "./PingPdfPreview.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const CustomLoading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full height of the viewport or parent container
      textAlign: "center",
    }}
  >
    <div>
      <h3>Loading your PDF...</h3>
      <p>Please wait a moment</p>
    </div>
  </div>
);

type PDFPreviewProps = {
  downloadUrl: string;
  fileName: string;
  documentOptions?: DocumentProps["options"];
  onClose?(): void;
  onEditClick?: () => void;
};

const LARGE_SCREEN_WIDTH_THRESHOLD = 2500;
const PDF_NORMAL_SCREEN_WIDTH = 750;
const PDF_LARGE_SCREEN_WIDTH = 1000;

export const PingPdfPreview: FC<PDFPreviewProps> = ({
  downloadUrl,
  fileName,
  documentOptions,
  onClose,
  onEditClick,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const { accessToken } = useAuth();

  const handleDownload = useCallback(() => {
    initiateDownload(downloadUrl, accessToken, fileName);
  }, [downloadUrl, fileName]);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    [],
  );

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const clientHeight = container.clientHeight;
    const scrollPosition = scrollTop + clientHeight / 2;

    // Find all page elements
    const pages = container.getElementsByClassName(
      "PingPdfPreview__PdfContainer__Page",
    );

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i] as HTMLElement;
      const pageTop = page.offsetTop;
      const pageBottom = pageTop + page.offsetHeight;

      if (scrollPosition >= pageTop && scrollPosition <= pageBottom) {
        setCurrentPage(i + 1);
        break;
      }
    }
  }, []);

  const windowSize = useWindowSize();

  return (
    <div className="PingPdfPreview">
      <div className="PingPdfPreview__Controls">
        {onClose && <PingCircleIconButton iconName="close" onClick={onClose} />}
        <div className="PingPdfPreview__Controls__PageInfo">
          {numPages && `Page ${currentPage} of ${numPages}`}
        </div>

        <div className="PingPdfPreview__Controls__FileOptions">
          <PingButton
            onClick={onEditClick}
            className="PingPdfPreview__Controls__EditButton"
            label="Update"
          />

          {/* Not an accessible button - only an icon is read out as download_file, and no relevant label. */}
          <SiteHeaderActionButton
            className={"PingPdfPreview__Controls__DownloadButton"}
            title="Download File"
            iconName="download_file"
            tooltipPlacement="left"
            onClick={() => handleDownload()}
          />
        </div>
      </div>
      <div
        className="PingPdfPreview__PdfContainer"
        ref={containerRef}
        onScroll={handleScroll}
      >
        <ReactPdfDocument
          loading={CustomLoading}
          file={downloadUrl as string}
          onLoadSuccess={onDocumentLoadSuccess}
          className="PingPdfPreview__PdfContainer__PdfDocument"
          options={documentOptions}
        >
          {numPages !== null &&
            Array.from({ length: numPages }, (_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={
                  windowSize.width >= LARGE_SCREEN_WIDTH_THRESHOLD
                    ? PDF_LARGE_SCREEN_WIDTH
                    : PDF_NORMAL_SCREEN_WIDTH
                }
                className="PingPdfPreview__PdfContainer__Page"
              />
            ))}
        </ReactPdfDocument>
      </div>
    </div>
  );
};
