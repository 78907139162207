import { FC, useMemo } from "react";
import { Placement } from "@floating-ui/react";
import cx from "classnames";

import {
  LucideIconName,
  PingLucideIcon,
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "@repo/ping-react-js";

import {
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
} from "utils/submission";
import { getInceptionDateStatus } from "features/submission-dashboard/inception-date-status";
import { SovDataType } from "ts-types/DataTypes";

import "./PVSubmissionPill.scss";

type PVSubmissionPillProps = {
  sov: SovDataType;
  tooltipPlacement?: Placement;
};

const iconSize = 15;

export const PVSubmissionPill: FC<PVSubmissionPillProps> = ({
  sov,
  tooltipPlacement = "right",
}) => {
  const triageOverallResultData = getHumanFriendlyTriageScore(
    sov?.triage_rules_overall_result,
    sov?.is_ping_ready
  );
  const readinessResult = getHumanFriendlyDataReadinessScore(
    sov.data_readiness_score
  );

  const inceptionDateStatus = useMemo(
    () =>
      getInceptionDateStatus(
        sov?.inception_date,
        "PVSubmissionPill__DaysToIncept"
      ),
    [sov]
  );

  return (
    <PingTooltip placement={tooltipPlacement}>
      <PingTooltipTrigger asChild>
        <div className="PVSubmissionPill">
          <div
            className={cx(
              "PVSubmissionPill__PillItem",
              "PVSubmissionPill__DaysToIncept",
              inceptionDateStatus.additionalClassName
            )}
          >
            <div>{inceptionDateStatus.label}</div>
          </div>

          <div
            className={cx(
              "PVSubmissionPill__PillItem",
              "PVSubmissionPill__Readiness",
              "PVSubmissionPill__Readiness--" + readinessResult.level
            )}
          >
            <PingLucideIcon
              iconName={readinessResult.icon as LucideIconName}
              size={iconSize}
            />
          </div>

          <div
            className={cx(
              "PVSubmissionPill__PillItem",
              "PVSubmissionPill__Triage_Result",
              "PVSubmissionPill__Triage_Result--" +
                triageOverallResultData?.level
            )}
          >
            <PingLucideIcon
              iconName={triageOverallResultData.icon as LucideIconName}
              size={iconSize}
            />
          </div>
        </div>
      </PingTooltipTrigger>

      <PingTooltipContent>
        <div>{inceptionDateStatus.tooltip}</div>
        <div>Data Readiness: {readinessResult.label}</div>
        <div>Recommendation: {triageOverallResultData.label}</div>
      </PingTooltipContent>
    </PingTooltip>
  );
};
