import React, { FC, useEffect, useState, useMemo } from "react";
import cx from "classnames";

import {
  formatDateTimeStringWithAgo,
  formatShortDateTime,
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "@repo/ping-react-js";

import { PVSubmissionPill } from "features/submission-dashboard/PVSubmissionPill";
import { findSubmissionTitle } from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";
import { useAppSelector } from "utils/redux";

import "./PingVisionSubmissionListItem.scss";

type PingVisionSubmissionListItemProps = {
  sov: SovDataType;
  isSelected: boolean;
  onClickIncomingItem: (id: string) => void;
  onCheckboxChange: (id: string, checked: boolean) => void;
  onDoubleClickItem: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  itemIsChecked?: boolean;
  className?: string;
};

const KEEP_BAR_FOR_MILLISECONDS = 2000;

const extract_email_from_full_email_address = (full_email: string) => {
  const email = full_email.match(/<(.*)>/);
  return email ? email[1] : full_email;
};

const calculateMedian = (numbers: number[]) => {
  if (!numbers.length) return 0;
  const sorted = [...numbers].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 === 0
    ? (sorted[mid - 1] + sorted[mid]) / 2
    : sorted[mid];
};

export const PingVisionSubmissionListItem: FC<
  PingVisionSubmissionListItemProps
> = ({
  sov,
  isSelected,
  onClickIncomingItem,
  onCheckboxChange,
  onDoubleClickItem,
  itemIsChecked = false,
  className,
}) => {
  const userIdToUserMap = useAppSelector(
    (state) => state.settings.userIdToUserMap,
  );
  const isClaimed = sov.claimed_by_id !== null;
  const [isChecked, setIsChecked] = useState(itemIsChecked);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const progressPercentage = useMemo(() => {
    const inProgressJobs =
      sov?.jobs?.filter((job) => job.processing_status === "I") || [];

    if (inProgressJobs.length === 0) {
      setShowProgressBar(false);
      return 0;
    }

    const progressValues = inProgressJobs.map(
      (job) => job.processing_pct_complete || 0,
    );
    setShowProgressBar(true);
    return inProgressJobs.length > 1
      ? calculateMedian(progressValues)
      : progressValues[0];
  }, [sov?.jobs]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onCheckboxChange(sov.id, e.target.checked);
    setIsChecked(!isChecked);
  };

  const sentByContent = useMemo(() => {
    if (sov.from_email) {
      return extract_email_from_full_email_address(sov.from_email);
    } else {
      return "API";
    }
  }, [sov.from_email]);

  const claimedContent = useMemo(() => {
    return !sov.claimed_by_id ? null : (
      <PingTooltip placement="right-end">
        <PingTooltipTrigger asChild>
          <div className="PingVisionSubmissionListItem__Status__Claim">
            {`${userIdToUserMap?.[sov.claimed_by_id]?.full_name}` ||
              "Missing Full Name"}
          </div>
        </PingTooltipTrigger>
        <PingTooltipContent>
          {userIdToUserMap?.[sov.claimed_by_id]?.username}
        </PingTooltipContent>
      </PingTooltip>
    );
  }, [sov.claimed_by_id, userIdToUserMap]);

  return (
    <li
      className={cx(
        "PingVisionSubmissionListItem",
        {
          "PingVisionSubmissionListItem--Selected": isSelected,
          "PingVisionSubmissionListItem--Checked": isChecked,
          "PingVisionSubmissionListItem--withProgress": showProgressBar,
        },
        className,
      )}
      onClick={() => onClickIncomingItem(sov.id)}
      onDoubleClick={(e) => onDoubleClickItem(e)}
    >
      {showProgressBar && (
        <div
          className={cx("PingVisionSubmissionListItem__ProgressBar", {})}
          style={{ width: `${progressPercentage}%` }}
        />
      )}

      <div className="PingVisionSubmissionListItem__Content">
        <div className="PingVisionSubmissionListItem__Heading">
          <div className="PingVisionSubmissionListItem__Heading__TitleWrapper">
            <div className="PingVisionSubmissionListItem__Checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="PingVisionSubmissionListItem__Heading__Title">
              {findSubmissionTitle(sov)}
            </div>
          </div>
          <div className="PingVisionSubmissionListItem__Heading__Date">
            {sov.workflow_status__name}
          </div>
        </div>

        <div className="PingVisionSubmissionListItem__Status PingVisionSubmissionListItem__Row2">
          {sentByContent}
          {" • "}
          <PingTooltip>
            <PingTooltipTrigger asChild>
              <div className="PingVisionSubmissionListItem__Status__Time">
                {formatDateTimeStringWithAgo(sov.created_time)}
              </div>
            </PingTooltipTrigger>
            <PingTooltipContent>
              {formatShortDateTime(sov.created_time)}
            </PingTooltipContent>
          </PingTooltip>
          {isClaimed ? " • " : ""}
          {isClaimed ? claimedContent : ""}
          <PVSubmissionPill sov={sov} />
        </div>
      </div>
    </li>
  );
};
