import { toast } from "react-hot-toast";
import { PingMaterialIcon } from "@repo/ping-react-js";

import "./PingMultiSelectOptions.scss";

type PingMultiSelectOptionsProps = {
  selectedSovids: Set<string>;
  onClearSelection?: () => void;
  onMoveToProject?: () => void;
  onCommand?: () => void;
};

const SHOW_MOVE_TO_TAB = false;

// Global variable to store the toast ID
let multiSelectToastId: string | null = null;

export const updateMultiSelectToast = (
  selectedSovids: Set<string>,
  onClearSelection?: () => void,
  onCommand?: () => void,
  onMoveToProject?: () => void
) => {
  if (multiSelectToastId) {
    // If a toast already exists, update it
    toast.custom(
      () => (
        <PingMultiSelectOptions
          key={selectedSovids.size}
          selectedSovids={selectedSovids}
          onClearSelection={onClearSelection}
          onCommand={onCommand}
          onMoveToProject={onMoveToProject}
        />
      ),
      {
        id: multiSelectToastId,
        duration: Infinity
      }
    );
  } else {
    multiSelectToastId = toast.custom(
      () => (
        <PingMultiSelectOptions
          key={selectedSovids.size}
          selectedSovids={selectedSovids}
          onClearSelection={onClearSelection}
          onCommand={onCommand}
          onMoveToProject={onMoveToProject}
        />
      ),
      {
        duration: Infinity,
        position: "bottom-center"
      }
    );
  }
};

export const clearMultiSelectToast = () => {
  if (multiSelectToastId) {
    toast.remove(multiSelectToastId);
    multiSelectToastId = null;
  }
};

export const PingMultiSelectOptions = ({
  selectedSovids,
  onClearSelection,
  onMoveToProject,
  onCommand
}: PingMultiSelectOptionsProps) => {
  return (
    <div className="ping-multi-select-options">
      <button
        className="selected-count"
        onClick={() => {
          onClearSelection?.();
          clearMultiSelectToast();
        }}
      >
        <span>{selectedSovids.size} selected</span>
        <PingMaterialIcon className="" iconName={"close"} />
      </button>
      {SHOW_MOVE_TO_TAB && (
        <button className="move-to-project" onClick={onMoveToProject}>
          <PingMaterialIcon className="" iconName={"move_to_inbox"} />
          Move to
        </button>
      )}
      <button className="command" onClick={onCommand}>
        <PingMaterialIcon className="" iconName={"view_stream"} />
        Command
      </button>
    </div>
  );
};

export const PingActionsPanel = () => {
  return (
    <div className="ping-multi-select-options">
      <button className="command" onClick={() => {}}>
        <PingMaterialIcon className="" iconName={"view_stream"} />
        Command
      </button>
    </div>
  );
};
