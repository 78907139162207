import cx from "classnames";

import "./LoadingSpinner.scss";

type LoadingSpinnerProps = {
  size?: "small" | "medium" | "smaller";
  color?: string;
};

export const LoadingSpinner = ({ size = "medium", color }: LoadingSpinnerProps) => {
  const classes = cx("LoadingSpinner", {
    "LoadingSpinner--Smaller": size === "smaller",
    "LoadingSpinner--Small": size === "small",
    "LoadingSpinner--Medium": size === "medium",
  });

  const style = color
    ? ({ "--spinner-color": color } as React.CSSProperties)
    : undefined;

  return (
    <div className={classes} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
