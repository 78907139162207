import { api } from "./pvSlice";

type PingAoaRequest = {
  address?: string;
  source: string;
  company_name?: string | null;
  company_website?: string | null;
  company_ein?: string | null;
  llm_text_blob?: string | null;
};

type PingAoAOpenAiRequest = {
  llm_text_blob: string;
};

const TIMEOUT = 3600;

const getAoaDataUrl = () => {
  if (import.meta.env?.VITE_APP_ENV === "prd") {
    return "https://data-api.sovfixer.com/";
  } else if (import.meta.env?.VITE_APP_ENV === "prdeu") {
    return "https://data-api.eu.sovfixer.com/";
  } else if (import.meta.env?.VITE_APP_ENV === "stg") {
    return "https://data-api-staging.sovfixer.com/";
  } else {
    return "https://data-api-dev.sovfixer.com/";
  }
};

export const aoaAPI = api.injectEndpoints({
  endpoints: (build) => ({
    callOpenAi: build.query<any, PingAoAOpenAiRequest>({
      query({ llm_text_blob }: PingAoAOpenAiRequest) {
        if (import.meta.env?.VITE_APP_ENV === "local") {
          return {
            url:
              getAoaDataUrl() +
              `api/v1/enhance?sources=OAGC&llm_text_blob=${encodeURIComponent(llm_text_blob)}&timeout=${TIMEOUT}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${import.meta.env.VITE_APP_PING_AOA_TOKEN}`
            }
          };
        } else {
          return {
            url:
              getAoaDataUrl() +
              `api/v1/enhance?sources=OAGC&llm_text_blob=${encodeURIComponent(llm_text_blob)}&timeout=${TIMEOUT}`,
            method: "GET"
          };
        }
      }
    }),
    callAoA: build.query<any, PingAoaRequest>({
      query({
        address,
        source,
        company_name,
        company_website,
        company_ein
      }: PingAoaRequest) {
        const searchParams = new URLSearchParams();

        searchParams.append("timeout", TIMEOUT.toString());

        if (address) {
          searchParams.append("address", address);
        }
        if (source) {
          searchParams.append("sources", source);
        }
        if (company_name) {
          searchParams.append("company_name", company_name);
        }
        if (company_website) {
          searchParams.append("company_website", company_website);
        }
        if (company_ein) {
          searchParams.append("company_ein", company_ein);
        }

        if (import.meta.env?.VITE_APP_ENV === "local") {
          return {
            url: getAoaDataUrl() + `api/v1/enhance?${searchParams}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${import.meta.env.VITE_APP_PING_AOA_TOKEN}`
            }
          };
        } else {
          return {
            url: getAoaDataUrl() + `api/v1/enhance?${searchParams}`,
            method: "GET"
          };
        }
      }
    })
  }),
  overrideExisting: false
});

export const { useCallAoAQuery, useCallOpenAiQuery } = aoaAPI;
