import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import classNames from "classnames";
import {
  PingLucideIcon,
  PingPdfPreview,
  PingEmailPreview,
  PingCircleIconButton,
  PingButton,
  SiteHeaderActionButton,
} from "@repo/ping-react-js";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { DOCUMENT_TYPE, SovDataType } from "ts-types/DataTypes";
import {
  setPreviewPdf,
  setClearPreview,
  setPreviewEmail,
} from "reducers/inbox";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { getApiBaseUrl } from "src/utils";
import { useGetEmailCorrespondenceQuery } from "services/pvSlice";
import { FileChangeModal } from "components/modals";
import {
  initiateDownload,
  formatLongDate,
  CheckboxInput,
} from "@repo/ping-react-js";
import { useAuth } from "ping-vision-client/src/utils/hooks.ts";
import "./PVAllDocumentsPreviewPanel.scss";

export type DocumentType = Exclude<
  keyof typeof DOCUMENT_TYPE,
  "EMAIL_BODY_HTML" | "EMAIL_BODY_TXT"
>;

export const DOCUMENT_TYPES: DocumentType[] = Object.keys(DOCUMENT_TYPE).filter(
  (type) => type !== "EMAIL_BODY_HTML" && type !== "EMAIL_BODY_TXT",
) as DocumentType[];

export const excludedConvertTypes = [
  "SOVFIXER_JSON",
  "SOVFIXER_SCRUBBER",
  "SOVFIXER_OUTPUT",
  "EML",
];

type PVAllDocumentsPreviewPanelProps = {
  selectedItem: SovDataType;
  setInitialPreviewType?: Dispatch<SetStateAction<DocumentType | null>>;
  onSetShowArchived?: Dispatch<SetStateAction<boolean>>;
  onCollapseExpand?: () => void;
  isCollapsed?: boolean;
  initialPreviewType?: DocumentType | null;
  initialShowArchived?: boolean;
};

export const PVAllDocumentsPreviewPanel: FC<PVAllDocumentsPreviewPanelProps> =
  React.memo(
    ({
      selectedItem,
      onCollapseExpand,
      isCollapsed,
      initialShowArchived,
      initialPreviewType,
      onSetShowArchived,
      setInitialPreviewType,
    }) => {
      const dispatch = useAppDispatch();
      const documents = useMemo(
        () => selectedItem?.documents || [],
        [selectedItem],
      );
      const [activeType, setActiveType] = useState<DocumentType | null>(null);
      const [showOtherFiles, setShowOtherFiles] = useState<boolean>(true);
      const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
      const [changedFileType, setChangedFileType] =
        useState<DocumentType | null>(null);
      const [changedFileName, setChangedFileName] = useState<string>("");
      const [selectedDocument, setSelectedDocument] = useState<
        (typeof documents)[0] | null
      >(null);
      const [showArchived, setShowArchived] = useState(false);

      const documentsByType = useMemo(() => {
        return DOCUMENT_TYPES.reduce(
          (acc, type) => {
            const filteredDocuments = documents.filter(
              (d) => d.document_type === type && !d.is_archived,
            );
            if (filteredDocuments.length > 0) {
              acc[type] = filteredDocuments;
            }
            return acc;
          },
          {} as Record<DocumentType, typeof documents>,
        );
      }, [documents]);

      const archivedCount = useMemo(() => {
        return documents.filter((d) => d.is_archived).length;
      }, [documents]);

      const archivedDocumentsByType = useMemo(() => {
        return DOCUMENT_TYPES.reduce(
          (acc, type) => {
            const filteredDocuments = documents.filter(
              (d) => d.document_type === type && d.is_archived,
            );
            if (filteredDocuments.length > 0) {
              acc[type] = filteredDocuments;
            }
            return acc;
          },
          {} as Record<DocumentType, typeof documents>,
        );
      }, [documents]);

      const activeDocumentsPerType = useMemo(
        () => (activeType ? documentsByType[activeType] || [] : []),
        [activeType, documentsByType],
      );

      const accessToken = useAppSelector((state) => state.auth.accessToken);

      const documentPreviewType = useAppSelector(
        (state) => state.inbox.documentPreview?.type,
      );

      const documentPreviewUrl = useAppSelector((state) =>
        state.inbox.documentPreview?.type === "PDF"
          ? state.inbox.documentPreview?.url
          : null,
      );

      const previewedDocument = useMemo(
        () => documents.find((d) => d.url === documentPreviewUrl),
        [documents, documentPreviewUrl],
      );

      const downloadUrl = selectedDocument?.url
        ? new URL(selectedDocument.url, getApiBaseUrl()).toString()
        : null;

      const { data: email, isFetching } = useGetEmailCorrespondenceQuery(
        { sovid: selectedItem.id },
        { skip: documentPreviewType !== "EMAIL" },
      );

      const documentOptions = useMemo(
        () => ({
          httpHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        }),
        [accessToken],
      );

      const handleDocumentClick = useCallback(
        (doc: (typeof documents)[0]) => {
          if (
            (doc.document_type === "ACORD" ||
              doc.document_type === "LOSS_RUN") &&
            doc.filename.endsWith("pdf")
          ) {
            dispatch(setPreviewPdf(doc.url));
          } else {
            setSelectedDocument(doc);
          }
        },
        [dispatch],
      );

      const handleSetActiveType = useCallback(
        (type: DocumentType | null) => {
          if (isCollapsed) {
            if (setInitialPreviewType) {
              setInitialPreviewType(type);
            }
            if (onCollapseExpand) {
              onCollapseExpand();
            }
          }
          setShowArchived(false);
          setActiveType(type);
          dispatch(setClearPreview());

          if (type) {
            const currentActiveDocuments = documentsByType[type];
            const firstDoc = currentActiveDocuments?.[0];

            if (
              firstDoc?.document_type === "ACORD" ||
              (firstDoc?.document_type === "LOSS_RUN" &&
                firstDoc?.filename.endsWith(".pdf"))
            ) {
              dispatch(setPreviewPdf(firstDoc.url));
            } else if (type === "EML") {
              dispatch(setPreviewEmail(selectedItem.id));
            }
          }
        },
        [
          isCollapsed,
          onCollapseExpand,
          setInitialPreviewType,
          documentsByType,
          setActiveType,
          dispatch,
          selectedItem,
        ],
      );

      const onClose = useCallback(() => {
        dispatch(setClearPreview());
        handleSetActiveType(null);
      }, [dispatch, handleSetActiveType]);

      const handleEditClick = useCallback(() => {
        setIsChangeModalOpen(true);
      }, []);

      const toggleShowOtherFiles = () => {
        setShowOtherFiles((prev) => !prev);
      };

      const handleFileUpdate = useCallback(
        (newFileName: string, newDocumentType: DocumentType) => {
          setChangedFileName(newFileName);
          setChangedFileType(newDocumentType);
        },
        [],
      );

      const { accessToken: authAccessToken } = useAuth();

      const handleDownload = useCallback(
        (downloadUrl: string, filename: string) => {
          initiateDownload(downloadUrl, authAccessToken, filename);
        },
        [authAccessToken],
      );

      const handleUpdate = useCallback(
        (filename: string) => {
          const doc = documents.find((doc) => doc.filename === filename);
          setSelectedDocument(doc || null);
          setIsChangeModalOpen(true);
        },
        [documents],
      );

      const handleShowArchived = useCallback(() => {
        dispatch(setClearPreview());
        setActiveType(null);
        setShowArchived(true);

        if (isCollapsed) {
          if (onCollapseExpand) {
            onCollapseExpand();
          }

          if (onSetShowArchived) {
            onSetShowArchived(true);
          }
        }
      }, [isCollapsed, onCollapseExpand, onSetShowArchived, dispatch]);

      useEffect(() => {
        setSelectedDocument(
          (previewedDocument as (typeof documents)[0]) || null,
        );
      }, [previewedDocument]);

      useEffect(() => {
        if (!isCollapsed) {
          if (documentPreviewType === "EMAIL" && activeType !== "EML") {
            setShowArchived(false);
            setActiveType("EML");
          } else if (
            documentPreviewUrl &&
            selectedDocument &&
            selectedDocument?.filename.endsWith("pdf")
          ) {
            setShowArchived(false);
            setShowOtherFiles(false);
            const doc = documents.find((d) => d.url === documentPreviewUrl);
            const docActiveType = doc?.document_type as DocumentType;
            if (docActiveType !== activeType) {
              setActiveType(doc?.document_type as DocumentType);
            }
          }
        }
      }, [
        isCollapsed,
        selectedDocument,
        activeType,
        documentPreviewType,
        documents,
        setActiveType,
        documentPreviewUrl,
      ]);

      useEffect(() => {
        if (changedFileName || changedFileType) {
          const updatedDocument = documents.find(
            (doc) =>
              doc.filename === changedFileName &&
              doc.document_type === changedFileType,
          );
          if (updatedDocument) {
            if (changedFileType === "ACORD" || changedFileType === "LOSS_RUN") {
              if (updatedDocument.filename.endsWith(".pdf")) {
                dispatch(setPreviewPdf(updatedDocument.url));
              } else {
                dispatch(setClearPreview());
                handleSetActiveType(changedFileType);
                setSelectedDocument(updatedDocument);
              }
            } else if (changedFileType) {
              dispatch(setClearPreview());
              setSelectedDocument(updatedDocument);
              handleSetActiveType(changedFileType);
            }
            setChangedFileName("");
            setChangedFileType(null);
          }
        }
      }, [
        handleSetActiveType,
        changedFileName,
        changedFileType,
        documents,
        dispatch,
      ]);

      useEffect(() => {
        if (
          !showArchived &&
          (!activeType || !Object.keys(documentsByType).includes(activeType))
        ) {
          setActiveType("SOV");
        }
      }, [showArchived, activeType, documentsByType]);

      useEffect(() => {
        if (initialPreviewType) {
          handleSetActiveType(initialPreviewType);
        }
      }, [handleSetActiveType, initialPreviewType]);

      useEffect(() => {
        if (initialShowArchived) {
          handleShowArchived();
        }
      }, [handleShowArchived, initialShowArchived]);

      useEffect(() => {
        if (isCollapsed) {
          setActiveType(null);
          setShowArchived(false);
        }
      }, [isCollapsed]);

      const formatArchivedReason = (reason: string) => {
        const words = reason.toLowerCase().split("_");
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        return words.join(" ");
      };

      return (
        <div className="PVAllDocumentsPreviewPanel">
          <div
            className={classNames("PVAllDocumentsPreviewPanel__header", {
              "PVAllDocumentsPreviewPanel__header--no-border": isCollapsed,
            })}
          >
            <div className="PVAllDocumentsPreviewPanel__pills">
              {DOCUMENT_TYPES.map((type) => {
                const count = documentsByType[type]?.length || 0;
                if (count === 0) return null;

                return (
                  <button
                    key={type}
                    className={classNames("PVAllDocumentsPreviewPanel__pill", {
                      "PVAllDocumentsPreviewPanel__pill--active":
                        activeType === type,
                    })}
                    onClick={() => handleSetActiveType(type)}
                  >
                    {type === "EML" ? "EMAIL" : type.replace(/_/g, " ")}
                    <span className="PVAllDocumentsPreviewPanel__pill-count">
                      {count}
                    </span>
                  </button>
                );
              })}
              {archivedCount !== 0 && (
                <button
                  className={classNames("PVAllDocumentsPreviewPanel__pill", {
                    "PVAllDocumentsPreviewPanel__pill--active": showArchived,
                  })}
                  onClick={() => handleShowArchived()}
                >
                  ARCHIVED
                  <span className="PVAllDocumentsPreviewPanel__pill-count">
                    {archivedCount}
                  </span>
                </button>
              )}
            </div>
            {!isCollapsed && (
              <PingCircleIconButton
                className="PVAllDocumentsPreviewPanel__close-button"
                aria-label="Close Documents Preview Panel"
                iconName="close"
                onClick={onCollapseExpand}
              />
            )}
          </div>

          {!isCollapsed && (
            <div className="PVAllDocumentsPreviewPanel__content">
              {activeType !== "EML" && !showArchived && (
                <div className="PVAllDocumentsPreviewPanel__files-section">
                  {activeDocumentsPerType.length > 1 &&
                    documentPreviewType &&
                    (activeType === "ACORD" || activeType === "LOSS_RUN") && (
                      <div
                        className={`PVAllDocumentsPreviewPanel__toggle-files-button ${showOtherFiles ? "PVAllDocumentsPreviewPanel__toggle-files-button--increased-margin-class" : ""}`}
                      >
                        <CheckboxInput
                          label={
                            showOtherFiles
                              ? `Hide Other ${activeDocumentsPerType.length - 1} ${activeDocumentsPerType.length === 2 ? "File" : "Files"}`
                              : `Show Other ${activeDocumentsPerType.length - 1} ${activeDocumentsPerType.length === 2 ? "File" : "Files"}`
                          }
                          name="toggleShowOtherFiles"
                          isChecked={showOtherFiles}
                          onChange={() => toggleShowOtherFiles()}
                        />
                      </div>
                    )}
                  {!showArchived &&
                    (["ACORD", "LOSS_RUN"].includes(
                      activeType as DocumentType,
                    ) ? (
                      <ul className="PVAllDocumentsPreviewPanel__previewable-documents">
                        {activeDocumentsPerType.map((doc) => {
                          if (
                            !documentPreviewType ||
                            doc.url === documentPreviewUrl ||
                            activeDocumentsPerType.length === 1 ||
                            showOtherFiles
                          ) {
                            return (
                              <li key={doc.url}>
                                <button
                                  key={doc.url}
                                  className={classNames(
                                    "PVAllDocumentsPreviewPanel__previewable-document",
                                    {
                                      "PVAllDocumentsPreviewPanel__previewable-document--active":
                                        selectedDocument === doc,
                                    },
                                  )}
                                  onClick={() => handleDocumentClick(doc)}
                                >
                                  <PingLucideIcon
                                    iconName={
                                      doc.document_type === "EML"
                                        ? "Mail"
                                        : "File"
                                    }
                                    size={16}
                                  />
                                  <div>{doc.filename}</div>
                                </button>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    ) : (
                      <ul className="PVAllDocumentsPreviewPanel__non-previewable-documents">
                        {activeDocumentsPerType.map((doc, index) => (
                          <li
                            key={doc.url}
                            className="PVAllDocumentsPreviewPanel__non-previewable-document"
                          >
                            <div
                              className={classNames(
                                "PVAllDocumentsPreviewPanel__non-previewable-document__actions",
                                {
                                  "PVAllDocumentsPreviewPanel__non-previewable-document__actions--bottom-border":
                                    index !== activeDocumentsPerType.length - 1,
                                },
                              )}
                            >
                              <span className="PVAllDocumentsPreviewPanel__non-previewable-document__filename">
                                {doc.filename}
                              </span>
                              <div className="PVAllDocumentsPreviewPanel__non-previewable-document__actions__right-group">
                                {!excludedConvertTypes.includes(
                                  activeType as DocumentType,
                                ) && (
                                  <PingButton
                                    onClick={() => handleUpdate(doc.filename)}
                                    className="PVAllDocumentsPreviewPanel__non-previewable-document__edit-button"
                                    label="Update"
                                  />
                                )}
                                {/* Not an accessible button - only an icon is read out as download_file, and no relevant label. */}
                                <SiteHeaderActionButton
                                  className={
                                    "PVAllDocumentsPreviewPanel__non-previewable-document__download-button"
                                  }
                                  title="Download File"
                                  iconName="download_file"
                                  tooltipPlacement="left"
                                  onClick={() =>
                                    handleDownload(doc.url, doc.filename)
                                  }
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ))}
                </div>
              )}
              {["LOSS_RUN", "ACCORD"].includes(activeType as DocumentType) &&
                selectedDocument &&
                !selectedDocument?.filename.endsWith("pdf") && (
                  <section className="PVAllDocumentsPreviewPanel__selected-non-previewable-document">
                    <header className="PVAllDocumentsPreviewPanel__selected-non-previewable-document__header">
                      Selected File is {selectedDocument.filename}
                    </header>
                    <div className="PVAllDocumentsPreviewPanel__selected-non-previewable-document__content">
                      <PingButton
                        onClick={() => handleUpdate(selectedDocument.filename)}
                        className="PVAllDocumentsPreviewPanel__selected-non-previewable-document__edit-button"
                        label="Update"
                      />

                      {/* Not an accessible button - only an icon is read out as download_file, and no relevant label. */}
                      <SiteHeaderActionButton
                        className={
                          "PVAllDocumentsPreviewPanel__selected-non-previewable-document__download-button"
                        }
                        title="Download File"
                        iconName="download_file"
                        tooltipPlacement="left"
                        onClick={() =>
                          handleDownload(
                            selectedDocument.url,
                            selectedDocument.filename,
                          )
                        }
                      />
                    </div>
                  </section>
                )}

              {documentPreviewType && (
                <div className="PVAllDocumentsPreviewPanel__preview">
                  {documentPreviewType === "PDF" &&
                    downloadUrl &&
                    selectedDocument?.filename?.endsWith("pdf") && (
                      <PingPdfPreview
                        fileName={selectedDocument?.filename || ""}
                        downloadUrl={downloadUrl}
                        documentOptions={documentOptions}
                        onClose={onClose}
                        onEditClick={handleEditClick}
                      />
                    )}
                  {documentPreviewType === "EMAIL" && !isFetching && email && (
                    <PingEmailPreview content={email} onClose={onClose} />
                  )}
                  {documentPreviewType === "EMPTY" && (
                    <div className="PVAllDocumentsPreviewPanel__empty">
                      <PingCircleIconButton
                        aria-label="Close Document Preview"
                        iconName="close"
                        onClick={onClose}
                      />
                      <PVEmptyPanelMessage
                        className="PVAllDocumentsPreviewPanel__empty__message"
                        message="Select an attachment to preview from the attachments list"
                      />
                    </div>
                  )}
                </div>
              )}
              {Object.keys(archivedDocumentsByType).length > 0 &&
                showArchived &&
                Object.entries(archivedDocumentsByType).map(
                  ([type, documents]) => (
                    <section
                      key={type}
                      className="PVAllDocumentsPreviewPanel__archived-files-section"
                    >
                      <header className="PVAllDocumentsPreviewPanel__archived-type-header">
                        {type.replace(/_/g, " ")}
                      </header>
                      <ul className="PVAllDocumentsPreviewPanel__archived-documents">
                        {documents.map((doc) => (
                          <li
                            key={doc.url}
                            className="PVAllDocumentsPreviewPanel__archived-document"
                          >
                            <div className="PVAllDocumentsPreviewPanel__archived-document__content">
                              <span className="PVAllDocumentsPreviewPanel__archived-document__filename">
                                <span>{doc.filename}</span>
                                <SiteHeaderActionButton
                                  className={
                                    "PVAllDocumentsPreviewPanel__archived-document__download-button"
                                  }
                                  title="Download File"
                                  iconName="download_file"
                                  tooltipPlacement="left"
                                  onClick={() =>
                                    handleDownload(doc.url, doc.filename)
                                  }
                                />
                              </span>

                              {doc.archived_on && (
                                <span className="PVAllDocumentsPreviewPanel__archived-document__metadata">
                                  Archived Date:{" "}
                                  {formatLongDate(doc.archived_on)}
                                </span>
                              )}
                              {doc.archived_reason && (
                                <span className="PVAllDocumentsPreviewPanel__archived-document__metadata">
                                  Archived Reason:{" "}
                                  {formatArchivedReason(doc.archived_reason)}
                                </span>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </section>
                  ),
                )}
            </div>
          )}
          <FileChangeModal
            isOpen={isChangeModalOpen}
            onClose={() => setIsChangeModalOpen(false)}
            onClickOk={handleFileUpdate}
            currentFileName={selectedDocument?.filename || ""}
            pingId={selectedItem.id}
            currentDocumentType={
              selectedDocument?.document_type as DocumentType
            }
          />
        </div>
      );
    },
  );
