import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SovDataType } from "ts-types/DataTypes";

interface SelectedSovState {
  selectedSovItem: SovDataType | null;
}

const initialState: SelectedSovState = {
  selectedSovItem: null,
};

export const selectedSovSlice = createSlice({
  name: "selectedSov",
  initialState,
  reducers: {
    setSelectedSovItem: (state, action: PayloadAction<SovDataType | null>) => {
      state.selectedSovItem = action.payload;
    },
    updateSelectedSovItem: (
      state,
      action: PayloadAction<{
        id: string;
        changed_fields?: Partial<SovDataType>;
        changed_documents?: any[];
        changed_jobs?: any[];
      }>,
    ) => {
      if (state.selectedSovItem?.id === action.payload.id) {
        // Update basic fields if they exist
        if (action.payload.changed_fields) {
          state.selectedSovItem = {
            ...state.selectedSovItem,
            ...action.payload.changed_fields,
          };
        }

        // Update documents if they exist
        if (action.payload.changed_documents?.length) {
          const updatedDocuments = [...state.selectedSovItem.documents];
          action.payload.changed_documents.forEach((newDocument) => {
            const docIndex = updatedDocuments.findIndex(
              (doc) => doc.id === newDocument.id,
            );
            if (docIndex !== -1) {
              updatedDocuments[docIndex] = newDocument;
            } else {
              updatedDocuments.push(newDocument);
            }
          });
          state.selectedSovItem.documents = updatedDocuments;
        }

        // Update jobs if they exist
        if (action.payload.changed_jobs?.length) {
          const updatedJobs = [...state.selectedSovItem.jobs];
          action.payload.changed_jobs.forEach((newJob) => {
            const jobIndex = updatedJobs.findIndex(
              (job) => job.job_id === newJob.job_id,
            );
            if (jobIndex !== -1) {
              updatedJobs[jobIndex] = newJob;
            } else {
              updatedJobs.push(newJob);
            }
          });
          state.selectedSovItem.jobs = updatedJobs;
        }
      }
    },
  },
});

export const { setSelectedSovItem, updateSelectedSovItem } =
  selectedSovSlice.actions;

export default selectedSovSlice.reducer;
