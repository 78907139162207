import "./LoadingMessagePanel.scss";

import { LoadingSpinner } from "./LoadingSpinner";

const LoadingMessagePanel = () => {
  return (
    <div className="LoadingMessagePanel">
      <LoadingSpinner size="small" />
      <div>Fetching data…</div>
    </div>
  );
};

export { LoadingMessagePanel };
