import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useMeasure, useWindowSize } from "@uidotdev/usehooks";

import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PVSubmissionDetails } from "src/features/submission-dashboard/PVSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { TeamMembersManagement } from "features/submission-dashboard/TeamMembersManagement";
import {
  DocumentType,
  DOCUMENT_TYPES,
  PVAllDocumentsPreviewPanel,
} from "features/submission-dashboard/PVAllDocumentsPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { PVRibbon } from "src/features/submission-dashboard/PVRibbon";
import {
  useGetSubmissionList,
  useGetSubmissionHistoryList,
  useGetAdvancedSearchFields,
  SEARCH_PARAM_NAME,
} from "features/submission-dashboard/queries";
import {
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { useAppSelector } from "utils/redux";
import { useSlug, usePingId } from "utils/hooks";
import { usePanelSizes } from "features/submission-dashboard/usePanelSizes";
import {
  setSovs,
  setSubmissionHistory,
  setPreviewPdf,
  setClearPreview,
  setPreviewEmail,
  setCurrentCursorId,
} from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { setSelectedSovItem } from "services/selectedSovSlice";
import { SovDataType } from "ts-types/DataTypes";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { PanelContext } from "utils/context";
import { DashboardPanel } from "features/submission-dashboard/panel-types";
import { useSearchQueryBuilder } from "@repo/ping-react-js";

import "./PingVisionSubmissionDashboard.scss";

const SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX = 1440;
const SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX = 1100;

const enrichSovData = (
  sovData: { results: SovDataType[] } | undefined,
  submissionStatuses: { id: number; name: string }[] | undefined,
) => {
  // TODO: this should be a selector so that we can take advantage of the Redux
  // Toolkit cache.
  //
  // Example code:
  //
  // import { createSelector } from '@reduxjs/toolkit';
  //
  // export const selectEnrichedSubmissions = createSelector(
  //   pingApi.endpoints.getSubmissions.select(),
  //   pingApi.endpoints.getStatuses.select(),
  //   (submissions, statuses) => {
  //     // If either query is loading or errored, return undefined
  //     if (!submissions.data || !statuses.data) {
  //       return undefined;
  //     }
  //
  //     // Map submissions to include full status information
  //     return submissions.data.map(submission => {
  //       const status = statuses.data.find(s => s.id === submission.statusId);
  //
  //       return {
  //         ...submission,
  //         status: status
  //           ? { name: status.name, description: status.description }
  //           : { name: 'Unknown', description: 'Status information not available' }
  //       };
  //     });
  //   }
  // );
  //
  // And later in the component:
  //
  // const enrichedSubmissions = useSelector(selectEnrichedSubmissions);
  //

  if (!sovData || !submissionStatuses) {
    return [];
  }

  return sovData.results.map((r) => ({
    ...r,
    workflow_status__name:
      submissionStatuses.find(
        (s) => s.id.toString() === r.workflow_status_id.toString(),
      )?.name || "Unknown",
  }));
};

const PingVisionSubmissionDashboard = () => {
  const dispatch = useDispatch();
  const pingId = usePingId();
  const [showArchivedDocuments, setShowArchivedDocuments] = useState(false);
  const [isLeftNavVisible, setIsLeftNavVisible] = useState(true);
  const [isSubmissionListVisible, setIsSubmissionListVisible] = useState(true);
  const [isPreviewPanelVisible, setIsPreviewPanelVisible] = useState(true);
  const [initialPreviewType, setInitialPreviewType] =
    useState<DocumentType | null>(null);

  const sovs = useAppSelector((state) => state.inbox.sovs);
  const selectedSovItem = useAppSelector(
    (state) => state.selectedSov.selectedSovItem,
  );
  const submissionStatuses = useAppSelector(
    (state) => state.settings?.settings?.submission_status,
  );

  const { mode } = usePingVisionUrlStore();

  const detailMode = mode === APP_MODES.DETAIL;

  const slug = useSlug();

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened,
  );

  const history = useHistory();

  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();
  const { searchValues } = useSearchQueryBuilder(
    fields,
    history as History<unknown>,
    SEARCH_PARAM_NAME,
  );

  // First try to find the item in the current sovs list
  const currentSelectedItem = useMemo(
    () => sovs.find((sov) => sov.id === pingId),
    [sovs, pingId],
  );

  // If not found in current list but we have a pingId, use the stored selectedSovItem
  const selectedItem = useMemo(
    () => currentSelectedItem || (pingId ? selectedSovItem : null),
    [currentSelectedItem, selectedSovItem, pingId],
  );

  // Update selectedSovItem when a new item is selected
  useEffect(() => {
    if (currentSelectedItem) {
      dispatch(setSelectedSovItem(currentSelectedItem));
    }
  }, [currentSelectedItem, dispatch]);

  const documents = useMemo(
    () => selectedItem?.documents || [],
    [selectedItem],
  );

  const documentsByType = useMemo(() => {
    return DOCUMENT_TYPES.reduce(
      (acc, type) => {
        const filteredDocuments = documents.filter(
          (d) => d.document_type === type,
        );
        if (filteredDocuments.length > 0) {
          acc[type] = filteredDocuments;
        }
        return acc;
      },
      {} as Record<DocumentType, typeof documents>,
    );
  }, [documents]);

  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  const previousSelectedItemId = useRef<string | null>(null);

  const handleOpenPreview = useCallback(() => {
    if (selectedItem && selectedItem.id) {
      dispatch(setClearPreview());

      if (Object.keys(documentsByType).length > 0) {
        const documentTypes = Object.keys(documentsByType);

        if (documentTypes.includes("EML")) {
          dispatch(setPreviewEmail(selectedItem.id));
        } else if (documentTypes.includes("ACORD")) {
          const currentActiveDocuments = documentsByType["ACORD"];
          const firstDoc = currentActiveDocuments[0];
          dispatch(setPreviewPdf(firstDoc.url));
        } else if (documentTypes.includes("LOSS_RUN")) {
          const currentActiveDocuments = documentsByType["LOSS_RUN"];
          const firstDoc = currentActiveDocuments[0];
          dispatch(setPreviewPdf(firstDoc.url));
        }
      }
    }
  }, [selectedItem, documentsByType, dispatch]);

  const handleCollapseExpand = useCallback(() => {
    setIsPreviewPanelVisible(!isPreviewPanelVisible);
    if (!isPreviewPanelVisible) {
      setInitialPreviewType(null);
      setShowArchivedDocuments(false);
    }
  }, [isPreviewPanelVisible]);

  useEffect(() => {
    setLocalSelectedItem(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (
      selectedItem?.id &&
      selectedItem.id !== previousSelectedItemId.current
    ) {
      previousSelectedItemId.current = selectedItem.id;
      setIsPreviewPanelVisible(false);
      handleOpenPreview();
    }
  }, [handleOpenPreview, selectedItem]);

  // Add sort config state
  const [sortConfig, setSortConfig] = useState<{
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  }>({
    field: "created_time",
    direction: "desc",
  });

  // Get sort params from URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sortField = params.get("sort_field");
    const sortDirection = params.get("sort_direction");

    if (
      sortField &&
      (sortField === "inception_date" || sortField === "created_time")
    ) {
      setSortConfig((prev) => ({
        ...prev,
        field: sortField,
      }));
    }

    if (
      sortDirection &&
      (sortDirection === "asc" || sortDirection === "desc")
    ) {
      setSortConfig((prev) => ({
        ...prev,
        direction: sortDirection,
      }));
    }
  }, []);

  // Update URL when sort config changes
  const handleSortChange = useCallback(
    (newSortConfig) => {
      dispatch(setCurrentCursorId(null));
      const params = new URLSearchParams(window.location.search);
      params.set("sort_field", newSortConfig.field);
      params.set("sort_direction", newSortConfig.direction);
      history.push({ search: params.toString() });
      setSortConfig(newSortConfig);
    },
    [history, dispatch],
  );

  useGetSettingsQuery({});

  useGetNavQuery({});

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryList();

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  const { data: sovData } = useGetSubmissionList({
    sortConfig,
    searchValues,
  });

  useEffect(() => {
    const enrichedSovs = enrichSovData(sovData, submissionStatuses);
    dispatch(setSovs(enrichedSovs));
  }, [dispatch, submissionStatuses, sovData]);

  const windowSize = useWindowSize();

  // Check if we're in team management view based on URL path
  const isTeamManagementView =
    window.location.pathname.endsWith("/team-members");

  useEffect(() => {
    // Show/hide left nav based on window size.
    if (
      windowSize.width &&
      windowSize.width > SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX
    ) {
      setIsLeftNavVisible(true);
    } else {
      setIsLeftNavVisible(false);
    }

    // Always hide submission list in team management view
    if (isTeamManagementView) {
      setIsSubmissionListVisible(false);
      setIsPreviewPanelVisible(false);
    } else if (
      windowSize.width &&
      windowSize.width <= SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX
    ) {
      setIsSubmissionListVisible(false);
    } else {
      setIsSubmissionListVisible(true);
    }
  }, [windowSize, isTeamManagementView]);

  // To calculate panel sizes in percentages, we need the width of the left
  // ribbon.
  const [ribbonRef, { width: ribbonWidth }] = useMeasure();

  // Calculate panel sizes in percentages, based on a set of minimum and maximum
  // pixel sizes.
  const { getMinPanelSize, getMaxPanelSize } = usePanelSizes(
    windowSize.width,
    ribbonWidth,
  );

  return (
    <React.Fragment>
      <PanelContext.Provider
        value={{
          isLeftNavVisible,
          isSubmissionListVisible,
        }}
      >
        <PingCommandMenuModal
          isOpen={isCommandMenuOpened}
          onClose={() => {
            dispatch(setIsCommandMenuOpened(false));
          }}
        />

        <BaseLayout
          title="Ping Radar [beta]"
          shouldShowPoweredByPing={false}
          className="PingVisionSubmissionDashboard"
        >
          <MainContent
            hasTopPadding={false}
            paddingSize="slim"
            className="PingVisionSubmissionDashboard__Main"
          >
            {!detailMode && (
              <PVRibbon
                onClickToggle={() => setIsLeftNavVisible(!isLeftNavVisible)}
                hasBorder={isLeftNavVisible}
                ref={ribbonRef}
              />
            )}
            <PanelGroup
              direction="horizontal"
              autoSaveId="submission-dashboard"
              key={`${isLeftNavVisible}-${isSubmissionListVisible}-${!isPreviewPanelVisible}`}
            >
              {isLeftNavVisible && !detailMode && (
                <>
                  <Panel
                    key={JSON.stringify({ isLeftNavVisible })}
                    id={DashboardPanel.SIDEBAR}
                    order={1}
                    minSize={getMinPanelSize(DashboardPanel.SIDEBAR)}
                    maxSize={getMaxPanelSize(DashboardPanel.SIDEBAR)}
                  >
                    <PVPanel background="none">
                      <PingVisionSidebar />
                    </PVPanel>
                  </Panel>
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                </>
              )}
              {slug && !detailMode && isSubmissionListVisible && (
                <>
                  <Panel
                    id={DashboardPanel.SUBMISSION_LIST}
                    order={2}
                    minSize={getMinPanelSize(DashboardPanel.SUBMISSION_LIST)}
                    maxSize={getMaxPanelSize(DashboardPanel.SUBMISSION_LIST)}
                  >
                    <PVPanel>
                      <PingVisionSubmissionList
                        sortConfig={sortConfig}
                        onSortChange={handleSortChange}
                      />
                    </PVPanel>
                  </Panel>
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                </>
              )}
              <Panel
                id={DashboardPanel.SUBMISSION_DETAILS}
                order={3}
                minSize={getMinPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
                maxSize={getMaxPanelSize(DashboardPanel.SUBMISSION_DETAILS)}
              >
                {isTeamManagementView ? (
                  <PVPanel>
                    <TeamMembersManagement />
                  </PVPanel>
                ) : localSelectedItem ? (
                  <PVPanel>
                    <PVSubmissionDetails
                      setInitialPreviewType={setInitialPreviewType}
                      setShowArchivedDocuments={setShowArchivedDocuments}
                      selectedItem={localSelectedItem}
                      isPreviewPanelCollapsed={isPreviewPanelVisible}
                      onPreviewPanelCollapse={handleCollapseExpand}
                      hasPreviewableDocuments={
                        Object.keys(documentsByType).length > 0
                      }
                    />
                  </PVPanel>
                ) : (
                  <PVPanel>
                    <PVEmptyPanelMessage message="Select a submission to view details" />
                  </PVPanel>
                )}
              </Panel>
              {selectedItem && !isPreviewPanelVisible && (
                <>
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                  <Panel
                    id={DashboardPanel.FILE_PREVIEW}
                    order={4}
                    minSize={getMinPanelSize(DashboardPanel.FILE_PREVIEW)}
                    maxSize={getMaxPanelSize(DashboardPanel.FILE_PREVIEW)}
                  >
                    <PVPanel>
                      <PVAllDocumentsPreviewPanel
                        initialShowArchived={showArchivedDocuments}
                        initialPreviewType={initialPreviewType}
                        selectedItem={selectedItem}
                        onCollapseExpand={handleCollapseExpand}
                      />
                    </PVPanel>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </MainContent>
        </BaseLayout>
      </PanelContext.Provider>
    </React.Fragment>
  );
};

export default PingVisionSubmissionDashboard;
