import { FC, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useMeasure } from "@uidotdev/usehooks";
import {
  PingSearchQueryBuilder,
  useSearchQueryBuilder,
} from "@repo/ping-react-js";
import {
  SEARCH_PARAM_NAME,
  useGetAdvancedSearchFields,
  useGetSubmissionList,
} from "features/submission-dashboard/queries";

import {
  LoadingMessagePanel,
  PingVirtualWindow,
  useAuth,
  PingButton,
} from "@repo/ping-react-js";

import { PingVisionSubmissionListItem } from "features/submission-dashboard/PingVisionSubmissionListItem";
import {
  updateMultiSelectToast,
  clearMultiSelectToast,
} from "features/submission-dashboard/PingMultiSelectOptions";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { usePingId, useSlug, useTeamId } from "utils/hooks";
import {
  setIsCommandMenuOpened,
  setSelectedSubmissions,
} from "reducers/settings";
import {
  setCurrentCursorId,
  setSlug,
  setTeamId,
  setSovs,
} from "reducers/inbox";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionList.scss";

interface PingVisionSubmissionListProps {
  sortConfig: {
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  };
  onSortChange: (newSortConfig: {
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  }) => void;
}

const hasWorkflowMismatch = (
  workflowStatusId: number,
  inboxSlug: string,
): boolean => {
  const workflowToInboxMap: Record<number, string> = {
    1: "inbox",
    2: "inbox",
    3: "inbox",
    4: "in-progress",
    5: "completed",
    6: "closed",
  };

  const expectedInbox = workflowToInboxMap[workflowStatusId];
  const allowedSlugs = ["all", "my-issues", expectedInbox];

  return expectedInbox ? !allowedSlugs.includes(inboxSlug) : false;
};

export const PingVisionSubmissionList: FC<PingVisionSubmissionListProps> = ({
  sortConfig,
  onSortChange,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setMode } = usePingVisionUrlStore();

  const selectedItemId = usePingId();

  const sovs: SovDataType[] = useAppSelector((state) => state.inbox.sovs);
  const inboxSlug = useAppSelector((state) => state.inbox.slug);
  const { ssoUser } = useAuth();

  const workflowUpdates = useAppSelector(
    (state) => state.workflowUpdates.recentUpdates,
  );
  // Create a map of submissionId to workflow update outside the filter
  const workflowUpdateMap = useMemo(
    () =>
      workflowUpdates.reduce(
        (acc, update) => {
          acc[update.submissionId] = update;
          return acc;
        },
        {} as Record<string, (typeof workflowUpdates)[0]>,
      ),
    [workflowUpdates],
  );

  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();

  const { searchValues, setSearchValues, clearSearchValues } =
    useSearchQueryBuilder(fields, history, SEARCH_PARAM_NAME);

  const { data: sovData, isFetching: isFetchingSovData } = useGetSubmissionList(
    {
      ...(sortConfig?.field && sortConfig?.direction ? { sortConfig } : {}),
      searchValues,
    },
  );

  const remainingCount = sovData?.remaining_count;
  const nextCursorId = sovData?.cursor_id;

  const onSearch = useCallback(
    (data: Record<string, string> | null) => {
      if (!data) {
        clearSearchValues();
        dispatch(setCurrentCursorId(null));
        dispatch(setSovs([]));
        return;
      }

      setSearchValues(data);
      dispatch(setCurrentCursorId(null));
      dispatch(setSovs([]));
      setSearchValues(data);
    },
    [clearSearchValues, setSearchValues, dispatch],
  );

  const filteredSovs = useMemo(
    () =>
      sovs.filter((submission) => {
        const workflowUpdate = workflowUpdateMap[submission.id];

        // If there's no workflow update or we are in a general purpose slug, keep the submission

        if (!workflowUpdate || ["all", "my-issues", null].includes(inboxSlug))
          return true;

        // Keep only if it's the selected item AND was modified by another user
        return (
          workflowUpdate.submissionId === selectedItemId &&
          ssoUser?.userId !== workflowUpdate?.changedById
        );
      }),
    [sovs, workflowUpdateMap, selectedItemId, ssoUser?.userId, inboxSlug],
  );

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [cleared, setCleared] = useState<number>(0);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const slug = useSlug();
  const teamId = useTeamId();

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (teamId) {
      dispatch(setTeamId(teamId));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (selectedItems.size) {
      dispatch(setSelectedSubmissions([...selectedItems]));
      updateMultiSelectToast(
        selectedItems,
        () => {
          setSelectedItems(new Set());
          setCleared(Math.random());
        },
        () => {
          dispatch(setIsCommandMenuOpened(true));
        },
        () => {},
      );
    } else {
      clearMultiSelectToast();
      dispatch(setSelectedSubmissions([]));
    }
  }, [selectedItems, dispatch]);

  useEffect(() => {
    onSortChange({
      field: "created_time",
      direction: "desc",
    });
  }, [inboxSlug, onSortChange]);

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      setSelectedItems((prevSelected) => {
        const newSelected = new Set(prevSelected);

        if (isShiftDown && lastSelectedItem && checked) {
          const startIndex = sovs.findIndex(
            (sov) => sov.id === lastSelectedItem,
          );
          const endIndex = sovs.findIndex((sov) => sov.id === id);
          const [lower, upper] = [startIndex, endIndex].sort((a, b) => a - b);

          for (let i = lower; i <= upper; i++) {
            newSelected.add(sovs[i].id);
          }
        } else {
          if (checked) {
            newSelected.add(id);
          } else {
            newSelected.delete(id);
          }
        }

        setLastSelectedItem(checked ? id : null);
        return newSelected;
      });
    },
    [isShiftDown, lastSelectedItem, sovs],
  );

  const onClickIncomingItem = useCallback(
    (id: string) => {
      if (!teamId && !slug) {
        return;
      }
      if (isShiftDown) {
        handleCheckboxChange(id, !selectedItems.has(id));
        return;
      }

      const currentSearchParams = new URLSearchParams(window.location.search);
      currentSearchParams.set("selected", id);
      history.push(`${window.location.pathname}?${currentSearchParams}`);
    },
    [isShiftDown, teamId, history, slug, handleCheckboxChange, selectedItems],
  );

  const handleDoubleClickItem = useCallback(() => {
    if (isShiftDown) {
      return;
    }
    setMode(APP_MODES.DETAIL);
  }, [setMode, isShiftDown]);

  const currentIndex = sovs?.findIndex((s) => s.id === selectedItemId);

  const selectNextSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex < sovs.length - 1) {
      const nextSov = sovs[currentIndex + 1];
      onClickIncomingItem(nextSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  const selectPreviousSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex > 0) {
      const previousSov = sovs[currentIndex - 1];
      onClickIncomingItem(previousSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  useHotkeys("down", selectNextSov, [selectNextSov]);
  useHotkeys("up", selectPreviousSov, [selectPreviousSov]);
  useHotkeys("esc", () => {
    setSelectedItems(new Set());
    setLastSelectedItem(null);
  });

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(true);
    },
    { keyup: false, enableOnFormTags: ["input", "select", "textarea"] },
  );

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(false);
    },
    { keyup: true, enableOnFormTags: ["input", "select", "textarea"] },
  );

  const [submissionListRef, { height: submissionListHeight }] = useMeasure();

  const [resetScroll, setResetScroll] = useState(false);

  useEffect(() => {
    setResetScroll(true);
    // Reset the flag after a short delay to allow future sort changes to trigger scroll reset
    const timer = setTimeout(() => setResetScroll(false), 100);
    return () => clearTimeout(timer);
  }, [sortConfig]);

  const handleSortClick = (field: "inception_date" | "created_time") => {
    onSortChange({
      field,
      direction:
        sortConfig.field === field
          ? sortConfig.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    });
  };

  return (
    <div className="PingVisionSubmissionList__Container">
      <div className="PingVisionSubmissionList__Filters">
        <span className="PingVisionSubmissionList__SortByText">Sort By:</span>
        <div className="PingVisionSubmissionList__FilterButtons">
          <PingButton
            className={`sort-button ${sortConfig.field === "inception_date" ? "active" : ""}`}
            onClick={() => handleSortClick("inception_date")}
            label={`Inception Date ${sortConfig.field === "inception_date" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}`}
          />
          <PingButton
            className={`sort-button ${sortConfig.field === "created_time" ? "active" : ""}`}
            onClick={() => handleSortClick("created_time")}
            label={`Time Received ${sortConfig.field === "created_time" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}`}
          />
        </div>
      </div>

      <div className="PingVisionSubmissionList__Search">
        <PingSearchQueryBuilder
          fields={fields}
          values={searchValues}
          onChange={onSearch}
        />
      </div>

      <ul
        className="PingVisionSubmissionList"
        ref={submissionListRef}
        key={cleared}
      >
        {submissionListHeight && (
          <PingVirtualWindow
            windowHeight={submissionListHeight}
            items={filteredSovs}
            resetScroll={resetScroll}
            renderItem={(item: SovDataType) => {
              const key = `sov-${item.id}-${selectedItems.has(item.id)}`;
              const hasMismatch =
                !isFetchingSovData &&
                inboxSlug &&
                item?.workflow_status_id &&
                hasWorkflowMismatch(item?.workflow_status_id, inboxSlug);

              return (
                <PingVisionSubmissionListItem
                  itemIsChecked={selectedItems.has(item.id)}
                  key={key}
                  sov={item}
                  isSelected={selectedItemId === item.id}
                  onClickIncomingItem={onClickIncomingItem}
                  onCheckboxChange={handleCheckboxChange}
                  onDoubleClickItem={handleDoubleClickItem}
                  className={hasMismatch ? "workflow-status-mismatch" : ""}
                />
              );
            }}
            onEndReached={() => {
              if (nextCursorId && remainingCount && !isFetchingSovData) {
                dispatch(setCurrentCursorId(nextCursorId));
              }

              return Promise.resolve();
            }}
          />
        )}
        {isFetchingSovData && <LoadingMessagePanel />}
      </ul>
    </div>
  );
};
